<template>

    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            应用调试
        </div>
        <!-- 标题 end -->

        <!-- 虚拟设备详情 start -->
        <div class="virtual_device_msg">
            <div class="virtual_device_list">
                <div class="virtual_device_top">
                    <div class="virtual_device_item"><span class="virtual_device_item_title">虚拟设备ID：</span>{{ virtualDeviceData.deviceId}}</div>
                </div>
                <div class="virtual_device_bottom">
                    <div class="virtual_device_item"><span class="virtual_device_item_title">绑定用户：</span>{{ virtualDeviceData.userId}}</div>
                </div>
            </div>
        </div>
        <!-- 虚拟设备详情 end -->

        <!-- 调试卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '16px',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>功能调试</span>
            </div>


            <el-table
                ref="effectTable"
                class="effect_table"
                border
                :span-method="objectSpanMethod"
                :data="effectList"
                :height="cardHeight - 16"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                :row-class-name="tableRowClassName"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loading"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    width="50"
                    prop="sid"
                    label="SID">
                </el-table-column>

                <el-table-column
                    width="200"
                    prop="serviceName"
                    label="功能名称">
                </el-table-column>

                <el-table-column
                    width="50"
                    prop="aid"
                    label="AID">
                </el-table-column>

                <el-table-column
                    width="200"
                    prop="attributeName"
                    label="属性名称">
                </el-table-column>

                <el-table-column
                    width="250"
                    label="参数">
                    <template slot-scope="scope">
                        <el-input
                            v-if="scope.row.type === 'String' || scope.row.type === 'Raw'"
                            type="textarea" v-model="scope.row.value" placeholder=""
                            :maxlength="scope.row.string"
                            show-word-limit
                            size="mini"></el-input>
                        <el-input-number v-if="scope.row.type === 'Number'" controls-position="right"
                                         v-model="scope.row.value" :min="scope.row.min"
                                         :step="scope.row.step" :max="scope.row.max"
                                         size="mini" step-strictly></el-input-number>
                        <el-select v-if="scope.row.type === 'Boolean'"
                                   v-model="scope.row.value" placeholder="请选择" size="mini">
                            <el-option label="true" :value="true"></el-option>
                            <el-option label="false" :value="false"></el-option>
                        </el-select>
                        <el-select
                            v-if="scope.row.type === 'Enum' || scope.row.type === 'Fault'"
                            v-model="scope.row.value" placeholder="请选择" size="mini">
                            <el-option
                                v-for="item1 in scope.row.list"
                                :key="item1.id"
                                :label="item1.name"
                                :value="item1.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column
                    width="110"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   :disabled="scope.row.authority === 1"
                                   @click="report(scope.row)"
                                   :loading="scope.row.reportLoading">上报
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="logValue"
                    label="内容">
                </el-table-column>


            </el-table>

        </el-card>
        <!-- 调试卡片 end -->

    </div>

</template>

<script>
import {mapActions} from "vuex";
import MQTT from "../../utils/mqtt";
export default {

    name: "newApplicationDebug",

    data() {

        return {

            virtualDeviceData: {
                //虚拟设备ID
                deviceId: "",
                //绑定用户
                userId: ""
            },

            cardHeight: 300,

            effectList: [],

            hoverIndex: null,

            loading: false,

            //是否是初始化mqtt
            isFirst: true

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.virtualDeviceData.deviceId = this.$route.query.deviceId;
        this.virtualDeviceData.userId = this.$route.query.telephone;
        this.getMsg();
    },

    methods: {

        ...mapActions('effect', ['queryProductServiceList']),

        initMQTT() {
            this.mqtt = new MQTT({
                clientId: this.$route.query.clientId,
                username: this.virtualDeviceData.deviceId,
                password: this.$token.getToken().accessToken,
                clean: true,
                connectTimeout: 4000,
                reconnectPeriod: 1000
            }, (isSuccess, msg) => {
                if (!isSuccess) {
                    this.$dialog.showMessage("MQTT连接失败！", this.$config.TOAST_ERROR);
                    console.error(msg);
                    return;
                }
                if (isSuccess && this.isFirst) {
                    this.isFirst = false;
                    let msgId = new Date().getTime() + "" + (Math.floor(Math.random()*(99999-10000+1))+10000);
                    this.mqtt.mqttReceive(this.onMQTTMsg, [
                        `vsendcmd/${this.$route.query.clientId}/+`,
                        `c_rptstatus/${this.$route.query.clientId}/+`
                    ])
                }
            });
        },

        /**
         * mqtt回调监听
         * @param isSendResponse
         * @param msg
         */
        onMQTTMsg(topic, msg) {
            console.log('结果 => ', topic + "=>", this.$config.format(new Date().getTime(), true), msg);
            if (topic.indexOf('vsendcmd') !== -1) {
                this.setMsgToTable([msg]);
            } else if (topic.indexOf('c_rptstatus') !== -1) {
                if (msg === 1) {
                    this.$dialog.showMessage("上报失败！", this.$config.TOAST_ERROR);
                }
                if (msg === 0) {
                    this.$dialog.showMessage("上报成功！", this.$config.TOAST_SUCCESS);
                }
            }
        },

        setMsgToTable(msg) {

            let msgList = [];
            msg.forEach(item => {
                item.attrs.forEach(item1 => {
                    msgList.push({
                        sk: item.k,
                        ak: item1.k,
                        v: item1.v,
                        itemLog: item
                    })
                })
            })

            let isReport = true;
            msgList.forEach(item => {
                this.effectList.forEach(item1 => {
                    if (item.sk === item1.serviceKey && item.ak === item1.attributeKey) {
                        isReport = item1.authority !== 1
                        item1.value = item.v;
                        item1.logValue = JSON.stringify(item.itemLog);
                    }
                })
            })

            //将虚拟设备接收到的指令直接上报（模拟真实设备接收到指令后的上报场景）
            isReport && this.messagePush(JSON.stringify(msg))
        },

        /**
         * 发送上报mqtt消息
         * @param data 消息体
         */
        messagePush(data) {
            let msgId = new Date().getTime() + "" + (Math.floor(Math.random()*(99999-10000+1))+10000);
            let topic = `rptstatus/${this.$route.query.clientId}/Y/${msgId}`;
            this.mqtt.messagePush(topic, data);
        },

        report(row) {
            let isStop = false;
            row.reportLoading = true;
            let attrs = [];
            for (let i = row.index; i < (row.rowspan + row.index); i++) {
                attrs.push({
                    k: this.effectList[i].attributeKey,
                    v: this.effectList[i].value
                })
                console.log(this.effectList[i].type)
                if (
                    (
                        this.effectList[i].type === "Boolean" ||
                        this.effectList[i].type === "Enum" ||
                        this.effectList[i].type === "Fault" ||
                        this.effectList[i].type === "Number"
                    ) &&
                    (
                        this.effectList[i].value === "" ||
                        this.effectList[i].value === undefined
                    )
                ) {
                    isStop = true;
                    row.reportLoading = false;
                    this.$dialog.showMessage("请填写" + this.effectList[i].attributeName, this.$config.TOAST_WARNING);
                    return;
                }
            }
            if (isStop) return;
            let data = [{
                k: row.serviceKey,
                t: new Date().getTime(),
                attrs
            }];
            this.messagePush(JSON.stringify(data))
            row.reportLoading = false;
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 342;
        },

        /**
         * 获取自定义产品功能列表
         */
        getMsg() {
            this.loading = true;
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (!res.result) return;
                this.effectList = this.effectDataHandle(res.result);
                this.initMQTT();
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        /**
         * 处理格式化功能定义数据
         * @param {*} data
         */
        effectDataHandle(data) {
            let list = this.$config.deepCopy(data);
            console.log(list)
            let effectList = [];
            list.forEach(item => {
                item.productAttributeList.forEach((item1, index1) => {
                    let list1 = [];
                    if (item1.dataType === 3 || item1.dataType === 7) {
                        let enumList = JSON.parse(item1.enumValues);
                        for (let j = 0; j < enumList.length; j++) {
                            for (let key in enumList[j]) {
                                if (key !== 'id') {
                                    list1.push({
                                        id: key,
                                        name: enumList[j][key]
                                    })
                                }
                            }
                        }
                    }
                    effectList.push({
                        list: list1,
                        index: effectList.length,
                        code: item.code,
                        attributeCode: item1.code,
                        aid: item1.attributeId,
                        serviceName: item.name,
                        serviceKey: item.serviceKey,
                        effectType: item.type,
                        attributeName: item1.name,
                        attributeKey: item1.attributeKey,
                        authority: item.authority,
                        format: item1.dataType,
                        type: this.$config.getMsgItemUtil(this.$message.formatIDList, item1.dataType, 'id', 'name'),
                        rowspan: item.productAttributeList.length > 1 ? (index1 == 0 ? item.productAttributeList.length : 0) : 1,
                        colspan: index1 == 0 ? 1 : 0,
                        endNum: item1.maxIntValue,
                        startNum: item1.minIntValue,
                        //最大值
                        max: parseInt(item1.maxValue),
                        //最小值
                        min: parseInt(item1.minValue),
                        //步长
                        step: parseInt(item1.stepValue),
                        unit: item1.unit,
                        string: item1.maxLength,
                        sid: item.serviceId,
                        value: (item1.dataType === 1 || item1.dataType === 2) ? item1.minIntValue ? parseInt(item1.minIntValue) : undefined : "",
                        reportLoading: false,
                        logValue: "—"
                    })
                })
            });
            return effectList;
        },

        /**
         * 设置列表合并
         * @param row 行数据
         * @param columnIndex 行数据列号
         * @returns {{colspan: (string|*), rowspan: (string|*)}}
         */
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 5 || columnIndex == 6) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.colspan,
                };
            }
        },

        tableRowClassName({row}){
            if (this.hoverIndex === row.code) {
                return "hover-bg";
            }
        },

        /**
         * ⿏标移⼊表格行
         * @param row
         * @param column
         * @param cell
         * @param event
         */
        cellMouseEnter(row, column, cell, event) {
            this.hoverIndex = row.code;
        },

        /**
         * ⿏标移出表格行
         */
        cellMouseLeave() {
            this.hoverIndex = null;
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
        this.mqtt.closeMQTT();
    }

}
</script>

<style scoped>
.virtual_device_msg {
    height: 92px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
}

.virtual_device_list {
    height: 72px;
    margin: 10px 20px;
    float: left;
    text-align: left;
}

.virtual_device_top {
    height: 22px;
    padding: 7px 0;
}

.virtual_device_bottom {
    height: 22px;
    margin-top: 10px;
}

.el-table {
    border-radius: 8px;
}

.virtual_device_item {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.65);
}

.virtual_device_item_title {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
}

.clearfix {
    text-align: left;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 0 24px;
    padding: 0;
}

/deep/ .el-card__header {
    padding: 10px 16px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}


.el-input-number, .el-select {
    width: 229px;
}
</style>
