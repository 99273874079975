<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            授权服务
        </div>
        <!-- 标题 end -->

        <!-- 授权证书概览 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            style="
                height: 120px;
                margin: 64px 24px 24px 24px;
            "
            shadow="never">

            <div slot="header" class="clearfix">
                <span>授权证书概览</span>
                <el-button
                    size="mini"
                    plain
                    type="primary"
                    @click="apply()"
                    style="float: right; padding: 5px;">
                    申请证书
                </el-button>
            </div>

            <el-row style="text-align: center;">
                <el-col :span="6">
                    <div class="empower_title">总授权申请数(个)</div>
                    <div class="empower_msg">
                        <span class="empower_msg_number">{{ overview.totalAuthorization }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="empower_title">产品授权申请数(个)</div>
                    <div class="empower_msg">
                        <span class="empower_msg_number">{{ overview.totalProductAuthorization }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="empower_title">服务授权申请数(个)</div>
                    <div class="empower_msg">
                        <span class="empower_msg_number">{{ overview.totalServiceAuthorization }}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="empower_title">已激活总数(个)</div>
                    <div class="empower_msg">
                        <span class="empower_msg_number">{{ overview.totalActivation }}</span>
                    </div>
                </el-col>
            </el-row>

        </el-card>
        <!-- 授权证书概览 end -->

        <!-- 授权列表 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>授权清单</span>
                <el-link type="primary" class="title_help">需要帮助？</el-link>
            </div>

            <el-form :inline="true" size="small" :model="form">
                <el-form-item>
                    <el-input v-model.trim="form.keyword" clearable placeholder="产品名称/服务名称/PID/SID" @clear="getMsg(1)"
                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="form.time"
                        type="datetimerange"
                        clearable
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.product" clearable placeholder="授权对象">
                        <el-option
                            v-for="item in $message.empowerObjectList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.objective" clearable placeholder="授权目的">
                        <el-option
                            v-for="item in $message.empowerObjectiveList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-table
                ref="empowerTable"
                class="empower_table"
                :data="empowerMsg.list"
                border
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                :row-class-name="tableRowClassName"
                :span-method="objectSpanMethod"
                :height="tableHeight"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">

                <el-table-column
                    fixed="left"
                    width="100"
                    label="序号"
                    type="index">
                </el-table-column>

                <el-table-column
                    fixed="left"
                    min-width="125"
                    label="授权对象">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.empowerObjectList, scope.row.type, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    fixed="left"
                    min-width="200"
                    label="授权产品/服务">
                    <template slot-scope="scope">
                        <div class="object_name" :title="scope.row.objectName + '_' + scope.row.objectId">
                            {{ scope.row.objectName + '_' + scope.row.objectId }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="125"
                    label="授权目的">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === 1">
                            {{
                                $config.getMsgItemUtil($message.empowerObjectiveList, scope.row.objective, 'id', 'name')
                            }}
                        </span>
                        <span v-else>
                            —
                        </span>
                    </template>
                </el-table-column>



                <el-table-column
                    min-width="200"
                    label="激活方式">
                    <template slot-scope="scope">
                        {{
                            $config.getMsgItemUtil($message.activeTypeList, scope.row.activeType, 'id', 'name')
                        }}
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="150"
                    prop="totalAuthorization"
                >
                    <template slot="header" slot-scope="scope">
                        可授权总数
                        <el-popover
                            placement="top-start"
                            title=""
                            trigger="hover"
                            content="通过审核后允许授权数量">
                            <i
                                :class="certificateIcon"
                                @mouseover="certificateIcon = 'empower_question_coicon icon-yuanyin'"
                                @mouseleave="certificateIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                                slot="reference"></i>
                        </el-popover>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="150"
                    label="剩余激活数">
                    <template slot="header">
                        剩余激活数
                        <el-popover
                            placement="top-start"
                            title=""
                            trigger="hover"
                            content="指剩余可使用的授权数">
                            <i
                                :class="fileIcon"
                                @mouseover="fileIcon = 'empower_question_coicon icon-yuanyin'"
                                @mouseleave="fileIcon = 'empower_question_alicon iconfont_al icon-yiwen'"
                                slot="reference"></i>
                        </el-popover>
                    </template>
                    <template slot-scope="scope">
                        {{ parseInt(scope.row.totalAuthorization) - parseInt(scope.row.totalActivation) }}
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="120"
                    prop="totalProbation"
                    label="试用数">

                </el-table-column>

                <el-table-column
                    min-width="220"
                    prop="createTime"
                    label="申请时间">
                </el-table-column>

                <el-table-column
                    min-width="200"
                    label="申请状态">
                    <template slot-scope="scope">
                        <span :style="{
                            border: '1px solid ' + setColor(scope.row.state),
                            color: setColor(scope.row.state),
                            borderRadius: '4px',
                            width: 'auto',
                            fontSize: '12px',
                            padding: '2px'
                        }">
                            {{ $config.getMsgItemUtil($message.empowerStatusList, scope.row.state, 'id', 'name') }}
                        </span>
                    </template>
                </el-table-column>

<!--                <el-table-column-->
<!--                    fixed="right"-->
<!--                    min-width="140">-->
<!--                    <template slot="header">-->
<!--                        设备凭证-->
<!--                        <el-popover-->
<!--                            placement="top-start"-->
<!--                            title=""-->
<!--                            trigger="hover"-->
<!--                            content="指烧录在设备端的相关凭证">-->
<!--                            <i-->
<!--                                :class="voucherIcon"-->
<!--                                @mouseover="voucherIcon = 'empower_question_coicon icon-yuanyin'"-->
<!--                                @mouseleave="voucherIcon = 'empower_question_alicon iconfont_al icon-yiwen'"-->
<!--                                slot="reference"></i>-->
<!--                        </el-popover>-->
<!--                    </template>-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="text" size="small" v-if="scope.row.state === 1"-->
<!--                                   @click="showVoucher(scope.row)">设备凭证-->
<!--                        </el-button>-->
<!--                        <span v-else>—</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->

<!--                <el-table-column-->
<!--                    min-width="125"-->
<!--                    fixed="right"-->
<!--                    label="自动计数">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button type="text" size="small" v-if="scope.row.state === 1"-->
<!--                                   @click="handleCommand(beforeHandleCommand(2, scope.row))">-->
<!--                            {{ scope.row.autoAuthorizationSwitch === 0 ? '打开' : '关闭' }}-->
<!--                        </el-button>-->
<!--                        <span v-else>—</span>-->
<!--                    </template>-->
<!--                </el-table-column>-->

                <el-table-column
                    min-width="200"
                    fixed="right"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toMsg(scope.row)">详情
                        </el-button>
<!--                        <el-button type="text" size="small" @click="handleCommand(beforeHandleCommand(0, scope.row))"-->
<!--                                   v-if="scope.row.state === 1">新增-->
<!--                        </el-button>-->
                        <el-button type="text" size="small" v-if="scope.row.state === 1"
                                   @click="showVoucher(scope.row)">设备凭证
                        </el-button>
                        <el-button type="text" size="small" @click="handleCommand(beforeHandleCommand(1, scope.row))"
                                   v-if="scope.row.state === 1">
                            {{ scope.row.authorizationSwitch === 1 ? "停用" : "启用" }}
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.state === 2" @click="recertification(scope.row)">重新认证</el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                :current-page="page"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(empowerMsg.totalRecords)">
            </el-pagination>

        </el-card>
        <!-- 授权列表 start -->

        <!-- 新增激活弹窗 -->
        <add-activation-dialog @getMsg="getMsg"></add-activation-dialog>

        <!-- 授权申请抽屉 -->
        <apply-authorization-vouchers-drawer @getMsg="getMsg"></apply-authorization-vouchers-drawer>

        <!-- 新增授权弹窗 -->
        <add-auth-dialog @getMsg="getMsg"></add-auth-dialog>

    </div>
</template>

<script>
import AddActivationDialog from "@/views/empower/components/dialog/addActivationDialog";
import ApplyAuthorizationVouchersDrawer from "@/views/empower/components/applyAuthorizationVouchersDrawer";
import {mapActions} from 'vuex';
import AddAuthDialog from "@/views/empower/components/dialog/addAuthDialog";
import Clipboard from "clipboard";

export default {

    name: "empower",

    components: {AddAuthDialog, ApplyAuthorizationVouchersDrawer, AddActivationDialog},

    data() {
        return {

            //加载动画开关
            loading: false,

            hoverIndex: null,

            certificateIcon: "empower_question_alicon iconfont_al icon-yiwen",

            fileIcon: "empower_question_alicon iconfont_al icon-yiwen",

            voucherIcon: "empower_question_alicon iconfont_al icon-yiwen",

            //查询表单条件
            form: {
                //产品名称/服务名称/PID/SID
                keyword: "",
                //时间区间
                time: "",
                //开始时间
                startTime: "",
                //结束时间
                endTime: "",
                //授权对象
                product: "",
                //授权目的
                objective: ""
            },

            //表格高度
            tableHeight: 50,

            //当前页
            page: 1,

            //授权申请数据
            empowerMsg: {
                list: [],
                totalRecords: 10
            },

            //授权证书概览
            overview: {
                totalAuthorization: 0,
                totalProductAuthorization: 0,
                totalServiceAuthorization: 0,
                totalActivation: 0
            },

            clipboard: null

        }
    },

    mounted() {
        setTimeout(() => this.setHeight(), 100);
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
        this.statistics();

        this.clipboard = new Clipboard('.copy');
        this.clipboard.on('success', (e) => {
            console.log("复制成功", e);
            this.$dialog.showMessage("复制成功", this.$config.TOAST_SUCCESS);
        });
    },

    methods: {

        ...mapActions('empower', ['queryAuthorization', 'exportDeviceCredentials', 'exportAuthorizationFile', 'updateAuth', 'statisticsAuth', 'getByAuthCode']),

        /**
         * 前往授权详情
         * @param row
         */
        toMsg(row) {
            this.$router.push({
                path: "/empowerMsg",
                query: {
                    code: row.code
                }
            })
        },

        tableRowClassName({row}){
            if (this.hoverIndex === row.code) {
                return "hover-bg";
            }
        },

        /**
         * ⿏标移⼊表格行
         * @param row
         * @param column
         * @param cell
         * @param event
         */
        cellMouseEnter(row, column, cell, event) {
            this.hoverIndex = row.code;
        },

        /**
         * ⿏标移出表格行
         */
        cellMouseLeave() {
            this.hoverIndex = null;
        },

        /**
         * 获取显示授权凭证弹窗
         * @param row
         */
        showVoucher(row) {
            this.getByAuthCode({
                authorizationCode: row.code
            }).then(res => {
                let msg = '<p style="font-weight: bold;">'+ (res.result.type === 1 ? "P" : "S") +'ID：</p><p><span id="productKeys">' + res.result.productKeys + '</span>' +
                    '<i class="el-icon-copy-document copy" data-clipboard-target="#productKeys"></i>' +
                    '</p>' +
                    '<br/>' +
                    '<p style="font-weight: bold;">' + (res.result.type === 1 ? "Product" : "Service") +'Key：</p><p><span id="appKey">' + res.result.appKey + '</span>' +
                    '<i class="el-icon-copy-document copy" data-clipboard-target="#appKey"></i>' +
                    '</p>' +
                    '<br/>' +
                    '<p style="font-weight: bold;">' + (res.result.type === 1 ? "Product" : "Service") +'Secret：</p><p><span id="appSecret">' + res.result.appSecret + '</span>' +
                    '<i class="el-icon-copy-document copy" data-clipboard-target="#appSecret"></i>' +
                    '</p>';
                this.$alert(msg, '授权凭证', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                    callback: action => {
                    }
                });
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 设置列表合并
         * @param row 行数据
         * @param columnIndex 行数据列号
         * @returns {{colspan: (string|*), rowspan: (string|*)}}
         */
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex !== 2) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.colspan,
                };
            }
        },

        /**
         * 授权证书概览
         */
        statistics() {
            this.statisticsAuth().then(res => {
                this.overview = {
                    totalAuthorization: res.result.totalAuthorization ? res.result.totalAuthorization : 0,
                    totalProductAuthorization: res.result.totalProductAuthorization ? res.result.totalProductAuthorization : 0,
                    totalServiceAuthorization: res.result.totalServiceAuthorization ? res.result.totalServiceAuthorization : 0,
                    totalActivation: res.result.totalActivation ? res.result.totalActivation : 0
                };
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取申请列表
         */
        getMsg(page) {
            if (page) this.page = page;
            this.loading = true;
            this.queryAuthorization({
                currPage: this.page,
                pageSize: 10,
                key: this.form.keyword,
                startCreateTime: this.form.startTime,
                endCreateTime: this.form.endTime,
                objective: this.form.objective,
                type: this.form.product
            }).then(res => {
                let list = [];
                res.result.list.forEach(item => {
                    let childrenList = item.objectInfo ? JSON.parse(item.objectInfo) : "";
                    console.log(childrenList)
                    if (Array.isArray(childrenList)) {
                        childrenList.forEach((msg, index) => {
                            let newItem = this.$config.deepCopy(item);
                            newItem.objectName = msg.objectName;
                            newItem.objectId = msg.objectId;
                            newItem.rowspan = index === 0 ? childrenList.length : 0;
                            newItem.colspan = index === 0 ? 1 : 0;
                            list.push(newItem)
                        })
                    } else {
                        let newItem = this.$config.deepCopy(item);
                        newItem.rowspan = 1;
                        newItem.colspan = 1;
                        list.push(item)
                    }
                })
                this.empowerMsg = {
                    list,
                    totalRecords: res.result.totalRecords
                };
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        timeChange(val) {
            this.form.startTime = val ? val[0] : '';
            this.form.endTime = val ? val[1] : '';
            this.getMsg(1);
        },

        /**
         * 文件下载
         * @param res
         */
        downloadFile(res, name, suffix) {
            let time = new Date().getTime();
            let fileName = `${name}_${time}.${suffix}`;
            let objectUrl = URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.download = decodeURI(fileName)
            link.href = objectUrl
            link.click();
        },

        /**
         * 申请授权凭证
         */
        apply() {
            this.$store.commit('empower/SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_TYPE', 0);
            this.$store.commit('empower/SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_VISIBLE', true);
        },

        /**
         * 重新认证
         * @param row
         */
        recertification(row) {
            this.$store.commit('empower/SET_APPLY_AUTHORIZATION_VOUCHERS_MSG', row);
            this.$store.commit('empower/SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_TYPE', 1);
            this.$store.commit('empower/SET_APPLY_AUTHORIZATION_VOUCHERS_DRAWER_VISIBLE', true);
        },

        /**
         * 重新设置下拉菜单参数
         * @param index
         * @param row
         * @returns {{index, row}}
         */
        beforeHandleCommand(index, row) {
            return {
                index: index,
                row: row,
            };
        },

        handleCommand(command) {
            let item = command.row
            switch (command.index) {
                //新增激活
                case 0:
                    this.$store.commit("empower/SET_ITEM_MSG", command.row);
                    this.$store.commit("empower/SET_ADD_AUTH_DIALOG_VISIBLE", true);
                    break;
                //停用/启用
                case 1:
                    this.$dialog.showConfirm({
                        content: item.authorizationSwitch === 1 ? "是否停用?" : "是否启用?",
                        title: "提示",
                        btn1: item.authorizationSwitch === 1 ? "停用" : "启用",
                        btn2: "取消",
                    }, () => {
                        this.updateAuth({
                            authorizationCode: item.code,
                            authorizationSwitch: item.authorizationSwitch === 1 ? 0 : 1
                        }).then(res => {
                            this.$dialog.showMessage(item.authorizationSwitch === 1 ? "停用成功" : "启用成功", this.$config.TOAST_SUCCESS);
                            this.getMsg();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        })
                    }, () => {
                    })
                    break;

                case 2:
                    this.$dialog.showConfirm({
                        content: item.autoAuthorizationSwitch === 1 ? "是否关闭自动计数?" : "是否开启自动计数?",
                        title: "提示",
                        btn1: item.autoAuthorizationSwitch === 1 ? "关闭" : "开启",
                        btn2: "取消",
                    }, () => {
                        this.updateAuth({
                            authorizationCode: item.code,
                            autoAuthorizationSwitch: item.autoAuthorizationSwitch === 1 ? 0 : 1
                        }).then(res => {
                            this.$dialog.showMessage(item.autoAuthorizationSwitch === 1 ? "关闭成功" : "开启成功", this.$config.TOAST_SUCCESS);
                            this.getMsg();
                        }, err => {
                            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        })
                    }, () => {
                    })
                    break;
            }
        },

        /**
         * 设置状态颜色
         * @param index
         */
        setColor(index) {
            return index === 0 || index === -1 ? '#1890FF' : index === 1 || index === -2 ? '#00B600' : '#FF2825';
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = window.innerHeight - 450 - 12;
            this.$nextTick(() => {
                document.getElementsByClassName('el-table__fixed-right')[0].style.height = (this.tableHeight - 9) + 'px';
            })
        },

        /**
         * 分页器
         * @param page
         */
        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight)
        this.clipboard.destroy();
    }

}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 24px;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}

.clearfix {
    text-align: left;
}

/deep/ .el-card__header {
    padding: 10px;
}

.empower_title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.60);
}

.empower_msg {
    margin-top: 5px;
}

.empower_msg_number {
    font-family: HelveticaNeue;
    font-size: 24px;
    color: #1890FF;
    margin-right: 3px;
}

.empower_question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.empower_question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}

.title_help {
    margin-left: 10px;
    margin-top: -5px;
    font-size: 13px;
}

/deep/ .el-date-editor {
    width: 350px;
}

.el-select {
    width: 100px;
}

.object_name {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}

/*/deep/ .el-table td.el-table__cell, /deep/ .el-table th.el-table__cell.is-leaf {*/
/*    border-bottom: 1px solid #EBEEF5;*/
/*    border-left: 1px solid #EBEEF5;*/
/*}*/

/*/deep/ .el-table__row td:last-child {*/
/*    border-right: 1px solid #EBEEF5;*/
/*}*/

</style>
