<template>
    <div class="body">
        <div v-if="type === 'jjfa'">
            <div class="title">可能的原因及解决方法如下：</div>

            <div class="title1">1. 设备已被其他用户绑定</div>
            <div class="content">如果您不知道是谁绑定了设备，您可以尝试重置该设备。查看说明书了解重置方式，也可通过他人分享使用设备。</div>

            <div class="title1">2. 设备未进入配网状态</div>
            <div class="content">请在尝试手动添加设备，并按照说明使设备进入配网状态。如果您不确定如何操作，请查看产品说明书或联系产品提供商。</div>

            <div class="title1">3. 网络环境差</div>
            <div class="content">请确保设备所处位置信号良好，尽量让手机/平板靠近设备（建议小于1米）。</div>

            <div class="title1">4. 手机/平板无线网或蓝牙未开启</div>
            <div class="content">为了发现和添加设备，请确保手机/平板的无线网络和蓝牙功能均已开启。</div>

            <div class="title1">5. 设备未通电或电量不足</div>
            <div class="content">请确保设备已正常通电或电量充足。</div>
        </div>
        <div v-else-if="type === 'czms'">
            <div class="title1">方法一</div>
            <div class="content">
                1. 使用已绑定该设备的小蜂连连APP账号登录APP。<br/>
                2. 进入设备页面，长按智能门锁卡片，在弹出的信息框中选择“<span style="color: #1a1a1a;font-weight: bold;">删除设备</span>”进行解绑。<br/>
                注意：解绑操作需要在门锁在线状态下进行。
            </div>
            <div class="title1">方法二</div>
            <div class="content">
                1. 长按设备后锁板中间的“<span style="color: #1a1a1a;font-weight: bold;">菜单键</span>”6秒。<br/>
                2. 输入<span style="color: #1a1a1a;font-weight: bold;">管理员密码</span>验证身份，完成解绑操作。
            </div>
            <div class="title1">方法三</div>
            <div class="content">
                进入设备端<span style="color: #1a1a1a;font-weight: bold;">系统菜单</span>，选择<span style="color: #1a1a1a;font-weight: bold;">网络设置</span>，点击<span style="color: #1a1a1a;font-weight: bold;">重置网络</span>，完成解绑操作
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "help",
    data() {
        return {
            type: ""
        }
    },
    mounted() {
        window.addEventListener('hashchange',this.onHASHChange);
        this.type = this.$route.query.type
    },
    methods: {
        onHASHChange() {
            this.type = this.$route.query.type
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onHASHChange);
    }
}
</script>

<style scoped>
    .body {
        margin: 1.5rem;
        text-align: left;
    }
    .title {
        font-weight: bold;
        font-size: 1rem;
        color: #1A1A1A;
    }
    .title1 {
        font-weight: bold;
        font-size: 0.88rem;
        color: #1A1A1A;
        margin-top: 1.5rem;
    }
    .content {
        font-weight: 400;
        font-size: 0.88rem;
        color: #666666;
        margin-top: 0.5rem;
    }
</style>
