<template>
    <div class="main">
        <div style="height:100%;">
            <el-button type="text" class="document_center" icon="el-icon-tickets" @click="openDoc()">文档中心</el-button>
            <img src="../../assets/login_left.png" class="login_left" @load="loadImage">
            <div class="login_right" v-if="isShowRight" :style="{
                width: 'calc(95% - ' + loginLeftWidth + 'px)',
                textAlign: 'center'
            }">

                <div class="card-form" :style="{
                    height: activeName == 'register' ? '400px' : '360px',
                    margin: `${activeName == 'register' ? '-200px' : '-170px'} auto`
                }" v-show="isLoadEnd">
                    <el-tabs v-model="activeName" @tab-click="switchTab">
                        <el-tab-pane label="登录" name="login">
                            <login ref="login"></login>
                        </el-tab-pane>
                        <el-tab-pane label="注册" name="register">
                            <register ref="register" @registerSuccess="registerSuccess"></register>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <el-link type="info" :style="{marginLeft: -loginLeftWidth/2 + 'px'}" class="copyright" target="_blank" href="https://beian.miit.gov.cn">Copyright&nbsp;&nbsp;&nbsp;©2022&nbsp;&nbsp;&nbsp;广州朗国电子科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备14024048号</el-link>

            </div>
        </div>

        <!-- 重置密码弹窗 -->
        <reset-password-dialog></reset-password-dialog>

        <!-- 设置密码弹窗 -->
        <set-password-dialog></set-password-dialog>

    </div>
</template>

<script>
import login from '@/views/login/components/login'
import register from '@/views/login/components/register'
import resetPasswordDialog from '@/views/login/components/dialog/resetPasswordDialog'
import setPasswordDialog from '@/views/login/components/dialog/setPasswordDialog'

export default {

    data() {
        return {

            //当前表单（login：登录  register：注册）
            activeName: "login",

            isLoadEnd: false,

            loginLeftWidth: 0,

            //是否展示右侧表单
            isShowRight: false
        }
    },

    components: {
        login,
        register,
        resetPasswordDialog,
        setPasswordDialog
    },

    mounted() {
        this.isLoadEnd = true;

        this.activeName = this.$route.query.type ? this.$route.query.type : "login";
        window.addEventListener('resize', this.setHeight);
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight)
    },

    methods: {

        /**
         * 打开文档中心
         */
        openDoc() {
            window.open(this.$message.docUrl, '_blank')
        },

        /**
         * 设置高度
         */
        setHeight() {
            this.loginLeftWidth = document.getElementsByClassName('login_left')[0].offsetWidth;
        },

        /**
         * 左侧图片加载完毕回调
         */
        loadImage() {
            //获取左侧图片宽度
            this.loginLeftWidth = document.getElementsByClassName('login_left')[0].offsetWidth;
            this.isShowRight = true;
        },

        /**
         * 顶部登录与注册切换
         * @param tab
         */
        switchTab(tab) {
            this.$refs[tab._props.name].initForm();
            this.$config.changeURLArg('type', tab._props.name)
        },

        /**
         * 注册完毕后回调
         */
        registerSuccess() {
            this.activeName = "login";
        }

    }

}
</script>

<style scoped>

.main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F7F7F7;
}

.login_left {
    float: left;
    height: 100%;
}

.login_right {
    height: 100%;
    float: left;
}

.card-form {
    width: 360px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 15px;
    position: relative;
    top: 50%;
    left: 5%;
}

/deep/ .el-tabs__nav-wrap::after {
    height: 0 !important;
}

/deep/ .el-tabs__nav {
    margin-left: 122px;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
}

.document_center {
    position: absolute;
    top: 5px;
    right: 20px;
    color: #838383;
}

.copyright {
    position: absolute;
    bottom: 16px;
}
</style>
