<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type == 1?'新增角色':(type == 2?'新增下级角色':(type == 3?'编辑角色':'查看角色'))"
        :visible.sync="visible"
        width="30%"
        :top="type == 4?'5vh':'15vh'"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item label="上级角色：" v-if="type == 2">
                <!-- <select-tree
                    ref="selectRoleTree"
                    @setParentMenu="setParentMenu"
                    :defaultProps="defaultProps"
                    :treedata="roleList">

                </select-tree> -->
                {{ form.parentRoleName }}
            </el-form-item>
            <el-form-item label="角色名称：" :prop="(type == 1 || type == 2 || type == 3)?'roleName':''">
                <el-input
                    v-model.trim="form.roleName"
                    placeholder="请输入角色名称"
                    show-word-limit
                    maxlength="10"
                    v-if="type != 4">
                </el-input>
                <span v-else>{{ form.roleName }}</span>
            </el-form-item>
            <el-form-item label="角色描述：">
                <el-input
                    v-model.trim="form.describe"
                    show-word-limit
                    maxlength="20"
                    placeholder="请输入角色描述"
                    v-if="type != 4">
                </el-input>
                <span v-else>{{ form.describe }}</span>
            </el-form-item>

            <el-form-item label="已选菜单：" v-if="type == 4">
                <el-tree
                    class="role_tree"
                    :props="props"
                    node-key="code"
                    :data="menuList">
                </el-tree>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="type == 3 ? modify() : submit()"
                       v-if="type != 4">{{ type == 3 ? '修 改' : '确 认' }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";
import selectTree from '@/components/selectTree'

export default {
    components: {
        selectTree
    },
    computed: {
        ...mapState('roleManage', ['addRoleDialogVisible', 'addRoleType', 'roleMsgItem'])
    },
    watch: {
        addRoleDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },
        addRoleType: {
            handler(newVal) {
                this.type = newVal;
            }
        }
    },
    data() {
        return {
            visible: false,
            type: 1,
            loading: false,
            formLabelWidth: "100px",
            form: {
                code: "",
                parentRoleName: "",
                parentCode: "",
                roleName: "",
                describe: ""
            },
            menuList: [],
            props: {
                children: "children",
                label: "name"
            },
            defaultProps: {
                children: "children",
                label: "roleName"
            },
            roleList: [],
            rules: {
                // parentRoleCode:[{ required: true, message: "请选择上级角色", trigger: "blur" }],
                roleName: [
                    {required: true, message: "请输入角色名称", trigger: "blur"},
                    {pattern: /^(?=[\S\s]{1,10}$)[\S\s]*/, message: '输入不能超过10个字符'}
                ]
            }
        }
    },
    methods: {
        ...mapActions('roleManage', ['closeRoleDialog', 'addRole', 'modifyRole', 'getRoleauthorityList', 'getAuthTreeByCode']),
        ...mapActions('menu', ['getUserAuthorityTree']),
        closeDialog() {
            this.closeRoleDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                code: "",
                parentRoleName: "",
                parentCode: "",
                roleName: "",
                describe: ""
            };
        },
        openDialog() {
            this.roleList = this.$message.roleList;
            this.type = this.addRoleType;

            console.log(this.roleMsgItem)
            this.setFrom(this.roleMsgItem);
            if (this.type == 4) {
                this.getAuthTree();
            }
        },
        getAuthTree() {
            this.getAuthTreeByCode({
                roleCode: this.roleMsgItem.code
            }).then(res => {
                this.menuList = res.result;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },
        setFrom(msg) {
            this.form = {
                code: msg.code,
                parentRoleName: msg.parentRoleName,
                parentCode: msg.parentCode,
                roleName: msg.name,
                describe: msg.remark,
            };
        },
        modify() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.modifyRole({
                        code: this.form.code,
                        name: this.form.roleName,
                        remark: this.form.describe
                    }).then(res => {
                        this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.logining = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        },
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.addRole({
                        name: this.form.roleName,
                        parentCode: this.form.parentCode,
                        remark: this.form.describe
                    }).then(res => {
                        this.$dialog.showMessage("添加成功", this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.logining = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        },
        setParentMenu(data) {
            console.log(data);
            this.form.parentRoleCode = data.id;
        }
    },
}
</script>

<style scoped>

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}
</style>
