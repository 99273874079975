<template>
    <el-select v-model="mineStatus" placeholder="请选择" :popper-append-to-body="false" collapse-tags style="width: 100%;" ref="selectTrees">
        <el-option :value="mineStatus" style="height: auto">
            <el-tree :data="treedata" :node-key="nodeKey"
                     ref="tree" highlight-current :props="defaultProps"
                     @node-click="handleNodeClick"></el-tree>
        </el-option>
    </el-select>
</template>

<script>
export default {

    name: "selectTree",

    props: {

        //树形数据
        treedata: {
            Type: Array,
            default: () => {
                return []
            }
        },

        //树形结构配置
        defaultProps: {
            Type: Object,
            default: () => {
                return {
                    children: "children",
                    label: "name"
                }
            }
        },

        //节点key
        nodeKey: {
            Type: String,
            default: "id"
        },

        //节点显示名称key
        labelKey: {
            Type: String,
            default: "name"
        }

    },

    data() {

        return {

            //选中项名称
            mineStatus: ''

        }

    },
    methods: {

        /**
         * 节点点击监听
         * @param data
         */
        handleNodeClick(data) {
            this.mineStatus = data[this.labelKey];
            this.$refs.selectTrees.handleClose()
            this.$emit('setParentMenu', data);
        },

        /**
         * 父组件设置文本框显示内容
         * @param msg
         */
        setMineStatus(msg) {
            this.$refs.tree.setCurrentKey(null);
            this.mineStatus = msg;
        }

    }

}
</script>

<style>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #fff;
}
</style>
