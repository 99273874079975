<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="密码修改"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">
            <el-form-item>
                验证账号：{{ form.tel }}
            </el-form-item>

            <el-form-item prop="code">
                <el-input
                    v-model="form.code"
                    placeholder="验证码">
                    <template slot="append" >
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading" :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}</el-button>
                    </template>
                </el-input>
            </el-form-item>
<!--            <el-form-item prop="oldPassword">-->
<!--                <el-input-->
<!--                    :type="isShowPwd1 ? 'text' : 'password'"-->
<!--                    v-model.trim="form.oldPassword"-->
<!--                    maxlength="20"-->
<!--                    placeholder="旧密码（不能超过20个字符）">-->
<!--                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd1 ? 'icon-buxianzhi' : 'icon-xianzhi')"-->
<!--                       @click="isShowPwd1 = !isShowPwd1"></i>-->
<!--                </el-input>-->
<!--            </el-form-item>-->
            <el-form-item prop="newPassword">
                <el-input
                    :type="isShowPwd2 ? 'text' : 'password'"
                    v-model.trim="form.newPassword"
                    maxlength="20"
                    placeholder="新密码（不能超过20个字符）">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd2 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd2 = !isShowPwd2"></i>
                </el-input>
            </el-form-item>
            <el-form-item prop="againPassword">
                <el-input
                    :type="isShowPwd3 ? 'text' : 'password'"
                    v-model.trim="form.againPassword"
                    maxlength="20"
                    placeholder="再次输入新密码（不能超过20个字符）">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd3 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd3 = !isShowPwd3"></i>
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
		</span>
    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('setting', ['updatePasswordDialogVisible']),
        ...mapState('login', ['userInfo'])
    },

    watch: {
        updatePasswordDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },

    data() {
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
                // password 是表单上绑定的字段
            } else if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            isShowPwd1: false,
            isShowPwd2: false,
            isShowPwd3: false,
            form: {
                tel: "",
                code: "",
                smsId: "",
                newPassword: "",
                againPassword: ""
            },
            phoneErr: "",
            codeBtnLoading: false,
            time: 60,
            isCodeBtnDisabled: false,
            codeBtnMsg: "获取验证码",
            visible: false,
            rules: {
                oldPassword: [{required: true, message: "请输入旧密码"}],
                code: [{required: true, message: "请输入验证码"}],
                newPassword: [
                    {required: true, message: "请输入新密码"},
                    {pattern: /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^])[A-Za-z\d@$!%*?&~#^]{8,}$/, message: "必须包含大小写字母、数字、特殊字符、长度不小于8"}
                ],
                againPassword: [{required: true, validator: validatePass2}],
            }
        }
    },

    methods: {

        ...mapActions('setting', ['closeUpdatePasswordDialog']),
        ...mapActions('login', ['loginout']),

        ...mapActions('login', ['sendUserSms', 'modifyPassword', 'modifyPasswordByPhone']),

        openDialog() {
            this.form.tel = this.userInfo.telephone;
        },

        closeDialog() {
            this.closeUpdatePasswordDialog();
            this.$refs.ruleForm.resetFields();
            this.phoneErr = "";
            this.form = {
                tel: "",
                code: "",
                smsId: "",
                newPassword: "",
                againPassword: ""
            }
        },

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;

                    /**
                     * 最好别删除这个注释，保不齐产品那天又改需求
                     */
                    // this.modifyPassword({
                    //     oldPassword: this.$md5(this.form.oldPassword),
                    //     password: this.$md5(this.form.newPassword)
                    // }).then(res => {
                    //     this.$dialog.showMessage("修改成功,请重新登录", this.$config.TOAST_SUCCESS);
                    //     this.loginout();
                    //     // this.closeDialog();
                    //     // this.$emit('getUserInfo');
                    // }, err => {
                    //     console.log(err);
                    //     this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    // }).finally(() => {
                    //     this.loading = false;
                    // })

                    this.modifyPasswordByPhone({
                        code: this.form.code,
                        password: this.$md5(this.form.newPassword),
                        smsId: this.form.smsId
                    }).then(res => {
                        this.$dialog.showMessage("修改成功,请重新登录", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.loginout();
                    }, err => {
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        getCode() {
            this.sendUserSms({
                type: '5'
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        }

    }

}
</script>

<style scoped>

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

/deep/ .el-dialog__footer {
    text-align: right;
}

.el-form {
    margin-top: 20px;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}
</style>
