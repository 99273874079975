<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="type == 0 ? '添加成员' : '修改成员'"
        :visible.sync="visible"
        width="35%"
        @close="closeDialog"
        @open="openDialog"
        center>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" :label-width="formLabelWidth">
            <el-form-item label="用户ID：" prop="userId" v-if="type == 0">
                <el-input placeholder="输入手机号点击搜索用户" :disabled="isSearch" v-model.trim="form.userId"
                          class="input-with-select">
                    <el-button slot="append" :disabled="isSearch" :loading="searchLoading" icon="el-icon-search"
                               @click="searchUser"></el-button>
                </el-input>
                <el-card
                    v-show="userMsg.userId">
                    <el-button type="text" icon="el-icon-error" size="medium" @click="cleanUser()"
                               class="close_img"></el-button>
                    <el-row>
                        <el-col :span="8">
                            <el-avatar icon="el-icon-s-custom" v-if="!userMsg.headImg"></el-avatar>
                            <el-avatar v-else :src="userMsg.headImg"></el-avatar>
                        </el-col>
                        <el-col :span="16">
                            <div style="color: #000;font-size: 14px;margin-top:5px;">{{ userMsg.userId }}</div>
                            <div style="color: #888;font-size: 12px;margin-bottom:5px;">{{ userMsg.userName }}</div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-form-item>
            <el-form-item label="备注名：">
                <el-input
                    type="text"
                    maxlength="20"
                    v-model.trim="form.remarks"
                    placeholder="输入备注名">
                </el-input>
            </el-form-item>
            <el-form-item label="角色：" prop="role">
                <!-- <el-select v-model="form.role" placeholder="选择角色">
                    <el-option
                        v-for="item in roleList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select> -->
                <select-tree
                    ref="roleSelectTree"
                    :treedata="roleTreeList"
                    :nodeKey="'code'"
                    @setParentMenu="changeRole">
                </select-tree>
            </el-form-item>
            <el-form-item label="可用产品：" required>
                <el-radio-group v-model="form.productId" @change="selectRadio">
                    <el-radio label="Y">全部产品</el-radio>
                    <el-radio label="N">指定产品</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-show="isShowProduct" :error="selectErr">
                <!-- <el-select v-model="form.productIdList" multiple placeholder="请选择产品">
                    <el-option
                        v-for="item in productList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select> -->
                <el-button type="primary" @click="selectProduct">选择产品</el-button>
                <div v-show="selectMsg.nameList">{{ selectMsg.nameList }}</div>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="type == 0 ? submit() : updateUser()">确 认</el-button>
        </span>
        <select-product-dialog ref="selectProductDialog" @change="selectProductChange"></select-product-dialog>
    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";
import selectTree from '@/components/selectTree';
import selectProductDialog from '@/views/setting/views/member/components/dialog/selectProductDialog';

export default {

    components: {
        selectTree,
        selectProductDialog
    },

    computed: {
        ...mapState('member', ['userDialogVisible', 'userDialogType', 'userItemMsg']),
        ...mapState('roleManage', ['roleMsg'])
    },

    watch: {

        userDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        },

        userDialogType: {
            handler(newVal) {
                console.log("dialog", newVal)
                this.type = newVal;
            }
        },

        userItemMsg: {
            handler(newVal) {
                this.form = {
                    userId: newVal.userId,
                    remarks: newVal.remark,
                    role: newVal.roleCode,
                    productId: newVal.allProduct,
                    productIdList: "",
                    code: newVal.code
                };
                this.$nextTick(() => {
                    this.isShowProduct = newVal.allProduct == 'N';
                    this.$refs.roleSelectTree.setMineStatus(newVal.roleName);
                    if (newVal.allProduct == 'N') {
                        this.selectProductChange(newVal.memberProductList);
                    }
                })
            }
        },

        roleMsg: {
            handler(newVal) {
                this.roleTreeList = newVal;
            },
            deep: true
        }

    },

    data() {

        return {
            visible: false,
            isSearch: false,
            formLabelWidth: "100px",
            loading: false,
            type: 0,
            isShowProduct: false,
            searchLoading: false,
            form: {
                userId: "",
                remarks: "",
                role: "",
                productId: "Y",
                productIdList: ""
            },
            userMsg: {
                headImg: "",
                userId: "",
                userName: ""
            },
            rules: {
                userId: [{required: true, message: "请输入手机号搜索", trigger: "blur"}],
                role: [{required: true, message: "请选择角色", trigger: "blur"}]
            },
            roleTreeList: [],
            productList: [
                {id: 1, name: "朗国水杯"},
                {id: 2, name: "朗国插座"},
                {id: 3, name: "朗国电灯"},
            ],
            selectMsg: {},
            selectErr: "",
            selectData: []
        }

    },

    methods: {

        ...mapActions('member', ['closeUserDialog', 'findUserBaseInfo', 'addMember', 'modifyMember']),
        ...mapActions('roleManage', ['roleTree']),

        cleanUser() {
            this.userMsg = {
                userId: "",
                userName: ""
            };
            this.form.userId = "";
            this.isSearch = false;
        },

        searchUser() {
            this.searchLoading = true;
            this.findUserBaseInfo({
                userName: this.form.userId
            }).then(res => {
                this.searchLoading = false;
                this.userMsg = res.result;
                this.isSearch = true;
            }, err => {
                this.searchLoading = false;
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },

        closeDialog() {
            this.$refs.ruleForm.resetFields();
            this.isSearch = false;
            this.form = {
                userId: "",
                remarks: "",
                role: "",
                productId: "Y",
                productIdList: ""
            };
            this.userMsg = {
                userId: "",
                userName: ""
            };
            this.$refs.roleSelectTree.setMineStatus("")
            this.selectErr = "";
            this.selectMsg = {};
            this.selectData = [];
            this.isShowProduct = false;
            this.closeUserDialog();
        },

        openDialog() {
            this.roleTree();
            this.type = this.userDialogType;
        },

        selectRadio(val) {
            console.log(val);
            this.isShowProduct = (val == 'N')
        },

        selectProductChange(e) {
            this.selectData = [];
            let codeList = [];
            let nameList = [];
            for (let i = 0; i < e.length; i++) {
                console.log(e[i].productCode ? e[i].productCode : e[i].code)
                e[i].code = e[i].productCode ? e[i].productCode : e[i].code;
                codeList.push(e[i].productCode ? e[i].productCode : e[i].code);
                nameList.push(e[i].name ? e[i].name : e[i].productName);
            }
            this.selectMsg = {
                codeList,
                nameList: nameList.join("、")
            }
            this.selectData = e;
        },

        selectProduct() {
            this.$store.commit("member/SET_SELECT_PRODUCT_DIALOG_VISIBLE", true);
            this.$refs.selectProductDialog.setMsg(this.$config.deepCopy(this.selectData))
            console.log(this.selectData)
        },

        /**
         * 选择角色回调
         * @param {选择的角色对象} data
         */
        changeRole(data) {
            this.form.role = data.code;
        },

        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (this.form.productId == 'N' && (!this.selectMsg.codeList || this.selectMsg.codeList.length == 0)) {
                    this.selectErr = "请选择产品"
                    return;
                }
                this.selectErr = "";
                if (valid) {
                    this.loading = true;
                    this.addMember({
                        allProduct: this.form.productId,
                        productCodes: this.form.productId == 'N' ? this.selectMsg.codeList.toString() : null,
                        remark: this.form.remarks,
                        roleCode: this.form.role,
                        userId: this.userMsg.userId
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage("添加成功", this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', this.$config.TOAST_ERROR);
                    })
                }
            })
        },

        updateUser() {
            this.$refs.ruleForm.validate((valid) => {
                if (this.form.productId == 'N' && (!this.selectMsg.codeList || this.selectMsg.codeList.length == 0)) {
                    this.selectErr = "请选择产品"
                    return;
                }
                this.selectErr = "";
                if (valid) {
                    this.loading = true;
                    this.modifyMember({
                        code: this.form.code,
                        productCodes: this.form.productId == 'N' ? this.selectMsg.codeList.toString() : null,
                        remark: this.form.remarks,
                        roleCode: this.form.role,
                        allProduct: this.form.productId
                    }).then(res => {
                        this.loading = false;
                        this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                        this.$emit("getMsg");
                        this.closeDialog();
                    }, err => {
                        this.loading = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', this.$config.TOAST_ERROR);
                    })
                }
            });
        }

    },
}
</script>

<style scoped>
.el-select {
    width: 100%;
}

.el-avatar {
    margin-top: 17px;
}

.el-card {
    width: 50%;
    margin-top: 10px;
    position: relative;
}

.close_img {
    color: black;
    position: absolute;
    right: 4px;
    margin-top: -20px;
    z-index: 9999;
}

/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog--center .el-dialog__footer {
    text-align: right !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

.el-form {
    margin-top: 20px;
}
</style>
