var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"authorization_msg_top"},[_c('el-card',{staticClass:"card1",attrs:{"body-style":{
                textAlign: 'left',
                padding: '0'
            },"shadow":"never"}},[_c('el-descriptions',[_c('el-descriptions-item',{attrs:{"label":"授权编号"}},[_vm._v(_vm._s(_vm.basicMsg.code))]),(_vm.basicMsg.type === 1)?_c('el-descriptions-item',{attrs:{"label":"授权目的"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.empowerObjectiveList, _vm.basicMsg.objective, 'id', 'name'))+" ")])]):_vm._e(),_c('el-descriptions-item',{attrs:{"label":"授权对象"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.empowerObjectList, _vm.basicMsg.type, 'id', 'name'))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"申请日期"}},[_vm._v(_vm._s(_vm.basicMsg.createTime))]),_c('el-descriptions-item',{attrs:{"label":"申请状态"}},[(_vm.basicMsg.state === 2)?_c('span',[_c('el-popover',{attrs:{"placement":"top-start","title":"","trigger":"hover","content":_vm.basicMsg.comments}},[_c('el-button',{staticStyle:{"padding":"0","color":"red","text-decoration":"underline"},attrs:{"slot":"reference","type":"text"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.empowerStatusList, _vm.basicMsg.state, 'id', 'name'))+" ")])],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.empowerStatusList, _vm.basicMsg.state, 'id', 'name'))+" ")])]),_c('el-descriptions-item',{attrs:{"label":"激活方式"}},[_vm._v(_vm._s(_vm.$config.getMsgItemUtil(_vm.$message.activeTypeList, _vm.basicMsg.activeType, 'id', 'name')))]),(_vm.basicMsg.allowProbation === 'Y')?_c('el-descriptions-item',{attrs:{"label":"支持试用"}},[_vm._v(_vm._s(_vm.basicMsg.probationPeriod)+"天")]):_vm._e(),_c('el-descriptions-item',{attrs:{"span":3,"label":'授权' + (_vm.basicMsg.type === 1 ? '产品' : '服务')}},[_vm._v(_vm._s(_vm.objectName)+" ")])],1)],1),_c('el-card',{staticClass:"card2",attrs:{"body-style":{
                padding: '0'
            },"shadow":"never"}},[_c('div',{staticClass:"card_title"},[_vm._v("申请总数")]),_c('div',{ref:"totalAuthorization",staticClass:"number",staticStyle:{"color":"#00B600"}},[_vm._v(_vm._s(_vm.$config.farmat(parseInt(_vm.basicMsg.totalAuthorization)))+" ")])]),_c('el-card',{staticClass:"card2",attrs:{"body-style":{
                padding: '0'
            },"shadow":"never"}},[_c('div',{staticClass:"card_title"},[_vm._v("已激活数量")]),_c('div',{ref:"totalActivation",staticClass:"number",staticStyle:{"color":"#FE7A23"}},[_vm._v(_vm._s(_vm.$config.farmat(parseInt(_vm.basicMsg.totalActivation))))])]),_c('el-card',{staticClass:"card2",attrs:{"body-style":{
                padding: '0'
            },"shadow":"never"}},[_c('div',{staticClass:"card_title"},[_vm._v("剩余数量")]),_c('div',{ref:"totalProbation",staticClass:"number",staticStyle:{"color":"#00B600"}},[_vm._v(_vm._s(_vm.$config.farmat(parseInt(_vm.basicMsg.totalAuthorization) - parseInt(_vm.basicMsg.totalActivation))))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }