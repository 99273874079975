<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="重置密码"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <!-- 重置密码表单 start -->
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm">

            <el-form-item :error="phoneErr">
                <el-input
                    type="text"
                    v-model.trim="form.tel"
                    placeholder="手机号">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="code">
                <el-input
                    v-model.trim="form.code"
                    placeholder="验证码">
                </el-input>
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                    :type="isShowPwd1 ? 'text' : 'password'"
                    v-model.trim="form.password"
                    @input="pwdChange"
                    placeholder="输入密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd1 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd1 = !isShowPwd1"></i>
                </el-input>
            </el-form-item>

            <el-form-item prop="confirmPassword" :error="pwdErr">
                <el-input
                    :type="isShowPwd2 ? 'text' : 'password'"
                    v-model.trim="form.confirmPassword"
                    placeholder="确认密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd2 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd2 = !isShowPwd2"></i>
                </el-input>
            </el-form-item>
        </el-form>
        <!-- 重置密码表单 end -->

        <!-- 弹窗底部按钮 start -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确 认</el-button>
        </span>
        <!-- 弹窗底部按钮 end -->

    </el-dialog>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['resetPasswordDialogVisible'])
    },

    watch: {
        //重置密码弹窗开关
        resetPasswordDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }
    },

    data() {

        //再次输入密码验证
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
                // password 是表单上绑定的字段
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }

        return {

            //密码显示开关
            isShowPwd1: false,

            //再次密码显示开关
            isShowPwd2: false,

            //修改按钮加载动画开关
            loading: false,

            form: {
                //验证码
                code: "",
                //手机号
                tel: "",
                smsId: "",
                password: "",
                confirmPassword: ""
            },

            //手机号验证错误提示
            phoneErr: "",

            //验证码按钮加载动画开关
            codeBtnLoading: false,

            //验证码重新获取倒计时时间（秒）
            time: 60,

            //手机号输入框禁用/启用开关
            isCodeBtnDisabled: false,

            //验证码按钮文案
            codeBtnMsg: "获取验证码",

            //弹窗按钮
            visible: false,

            rules: {
                // tel: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
                password: [
                    {required: true, message: "请输入密码", trigger: "blur"},
                    {pattern: /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^])[A-Za-z\d@$!%*?&~#^]{8,}$/, message: "必须包含大小写字母、数字、特殊字符、长度不小于8"}
                ],
                confirmPassword: [{required: true, validator: validatePass2}],
            },

            pwdErr: ""

        }

    },

    methods: {

        ...mapActions('login', ['closeResetPasswordDialog']),
        ...mapActions('login', ['sendSms', 'restPassword']),

        openDialog() {},

        pwdChange(val) {
            if (val === this.form.confirmPassword) {
                this.pwdErr = ""
            } else {
                this.pwdErr = "两次输入密码不一致!"
            }
        },

        /**
         * 关闭弹窗回调
         */
        closeDialog() {
            this.closeResetPasswordDialog();
            this.$refs.ruleForm.resetFields();
            this.form = {
                code: "",
                tel: "",
                smsId: "",
                password: "",
                confirmPassword: ""
            }
        },

        /**
         * 获取验证码
         */
        getCode() {
            if (!this.form.tel) {
                this.phoneErr = "请输入手机号"
                return
            }
            if (!this.$config.phoneRight(this.form.tel)) {
                this.phoneErr = "手机号格式不正确"
                return;
            }
            this.phoneErr = "";
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.form.tel,
                type: '3'
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        /**
         * 发送完成验证码后的倒计时处理
         */
        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        },

        /**
         * 提交重置密码表单
         */
        submit() {
            let isStart = true;
            if (!this.$config.phoneRight(this.form.tel)) {
                this.phoneErr = "手机号格式不正确"
                isStart = false;
            }
            if (!this.form.tel) {
                this.phoneErr = "请输入手机号"
                isStart = false;
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    this.loading = true;
                    this.restPassword({
                        phoneCode: this.form.tel,
                        code: this.form.code,
                        password: this.$md5(this.form.password),
                        smsId: this.form.smsId
                    }).then(res => {
                        this.$dialog.showMessage("重置成功", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                    }, err => {
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        }

    }

}
</script>

<style scoped>
.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}
</style>
