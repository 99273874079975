<template>
    <div>
        <div class="page_title">
            设备日志
        </div>


        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '4px 15px',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="通讯日志" name="first">
                    <communication-log ref="communicationLog"/>
                </el-tab-pane>
                <el-tab-pane label="上下线日志" name="second">
                    <online-and-offline-logs ref="onlineAndOfflineLogs"/>
                </el-tab-pane>
                <el-tab-pane label="绑定/解绑日志" name="third">
                    <binding-log ref="bindingLog"/>
                </el-tab-pane>
                <el-tab-pane label="网络配置日志" name="fourth">
                    <network-configuration-log ref="networkConfigurationLog"/>
                </el-tab-pane>
            </el-tabs>

        </el-card>
    </div>
</template>

<script>
import CommunicationLog from "@/views/maintain/components/deviceLogTabs/communicationLog";
import OnlineAndOfflineLogs from "@/views/maintain/components/deviceLogTabs/onlineAndOfflineLogs";
import BindingLog from "@/views/maintain/components/deviceLogTabs/bindingLog";
import NetworkConfigurationLog from "@/views/maintain/components/deviceLogTabs/networkConfigurationLog";
export default {

    name: "deviceLog",

    components: {NetworkConfigurationLog, BindingLog, OnlineAndOfflineLogs, CommunicationLog},

    data() {

        return {

            activeName: "first",

            cardHeight: 300

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
    },

    methods: {

        handleClick(tab, event) {
            this.setHeight();
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 153 - 8;
            this.$refs.communicationLog.setHeight();
            this.$refs.onlineAndOfflineLogs.setHeight();
            this.$refs.bindingLog.setHeight();
            this.$refs.networkConfigurationLog.setHeight();
        }

    }

}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 64px 24px 0 24px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

</style>
