<template>

    <div class="content" v-loading="loading">

        <div class="type" v-for="(item,index) in productList" :key="index">

            <div class="title">{{ JSON.parse(item.name).zh }}</div>

            <el-row>

                <el-col style="margin-right: 26px;width: auto;" v-for="(item1,index1) in item.children" :key="index1">

                    <div>

                        <div class="category_item">

                            <el-radio
                                v-model="radio"
                                :label="item1.code"
                                border
                                @click.native.prevent="cancelSelect(item1.code,item1)"
                                size="medium">
                                {{ JSON.parse(item1.name).zh }}
                            </el-radio>

                        </div>

                    </div>

                </el-col>

            </el-row>

            <el-divider v-if="index !== (productList.length - 1)"></el-divider>

        </div>

    </div>

</template>

<script>

import {mapState, mapActions, mapGetters} from "vuex";

export default {

    computed: {
        ...mapState('product', ['selectProductDrawerVisible', 'selectFirstCategory', 'selectCategoryMsg'])
    },

    watch: {

        selectProductDrawerVisible: {
            handler(newVal) {
                this.drawer = newVal;
            }
        },

        selectFirstCategory: {
            handler(newVal) {
                // this.item = newVal;
            },
            deep: true
        },

        searchSelectItemList: {
            handler(newVal) {
                console.log('searchSelectItemList', newVal)
                this.productList = newVal;
            },
            deep: true
        },

        item: {
            handler(newVal) {
                this.msg = newVal;
            },
            deep: true
        }

    },

    props: {

        item: {
            type: Object,
            default() {
                return {}
            }
        },

        searchSelectItemList: {
            type: Array,
            default: []
        }

    },

    data() {

        return {

            loading: false,

            drawer: false,

            msg: {},

            productList: [],

            radio: '',

            flag: false

        }

    },

    mounted() {
        this.init();
        this.handleOpen()
    },

    methods: {

        ...mapActions('product', ['closeSelectProductDrawer', 'closeAddProductTypeDrawer']),
        ...mapActions('category', ['queryChildsCategory']),
        ...mapGetters('product', ['getSelectCategoryMsg']),

        init() {
            this.msg = this.item;
            this.$nextTick(() => {
                this.radio = this.getSelectCategoryMsg().code ? this.getSelectCategoryMsg().code : '';
            })

        },

        handleOpen() {
            if (this.searchSelectItemList.length > 0) {
                this.productList = this.searchSelectItemList;
                return
            }
            this.queryChildsCategory({code: this.msg.id}).then(res => {
                this.productList = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.$emit("closeLoad")
            })
        },

        closeNoticeMsgDialog() {
            this.closeSelectProductDrawer();
        },

        cancelSelect(code, item) {
            this.radio === code ? this.radio = "" : this.radio = code
            this.$store.commit("product/SET_SELECT_CATEGORY_MSG", code === this.radio ? item : {});
        }

    }

}
</script>

<style scoped>
/deep/ .el-divider--horizontal {
    margin: 10px 0px 15px 0;
}

/deep/ .el-drawer__header {
    margin-bottom: 20px;
}

.title {
    padding-bottom: 15px;
    font-weight: bold;
}

.content {
    text-align: left;
    padding: 15px;
}

.category_item {
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.card_title {
    text-align: center;
    padding: 14px 0;
    width: 100%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card_title span {
    margin: 0 10px;
}

.image {
    margin: 0 auto;
    border: 1px solid #E1E1E1;
    border-radius: 3px;
    padding: 10px 20px 10px 10px;
}

/deep/ .el-radio__inner {
    border-radius: 20px;
    width: 14px;
    height: 14px;
}

/deep/ .el-radio__inner::after {
    border-radius: 20px;
    width: 16px;
    height: 16px;
    background-image: url("../../../../../assets/icon_selected.png");
    background-size: contain;
}

</style>
