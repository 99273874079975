<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            设备管理
        </div>
        <!-- 标题 end -->

        <!-- 设备概览 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            style="
                height: 120px;
                margin: 60px 20px 20px 20px;
            "
            shadow="always">

            <div slot="header" class="clearfix">
                <span>设备概览</span>
            </div>

            <el-row style="text-align: center;">
                <el-col :span="8">
                    <div class="device_title">总激活设备数(台)</div>
                    <div class="device_msg">
                        <span class="device_msg_number">{{ overview.totalActivation }}</span>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="device_title">在线设备数</div>
                    <div class="device_msg">
                        <span class="device_msg_number">{{ overview.totalBatchProductionAuthorization }}</span>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="device_title">离线设备数</div>
                    <div class="device_msg">
                        <span class="device_msg_number">{{ overview.totalDebuggingAuthorization }}</span>
                    </div>
                </el-col>
            </el-row>

        </el-card>
        <!-- 设备概览 end -->

        <!-- 设备列表 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>设备列表</span>
            </div>

            <el-form :inline="true" size="small" :model="form">
                <el-form-item>
<!--                    <el-input v-model.trim="form.keyword" clearable placeholder="设备ID/绑定用户" @clear="getMsg(1)"-->
<!--                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>-->

                    <el-input :placeholder="'请输入' + (selectKey === 1 ? '手机号码' : '设备ID')" v-model="form.keyword" clear="getMsg(1)"
                              @keyup.enter.native="getMsg(1)" @blur="getMsg(1)" class="input-with-select">
                        <el-select v-model="selectKey" :popper-append-to-body="false" slot="prepend" placeholder="请选择">
                            <el-option label="手机号码" :value="1"></el-option>
                            <el-option label="设备ID" :value="2"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="form.time"
                        type="datetimerange"
                        clearable
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="timeChange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.type" clearable placeholder="类型">
                        <el-option
                            v-for="item in $message.deviceTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select @change="getMsg(1)" :popper-append-to-body="false" v-model="form.status" clearable placeholder="状态">
                        <el-option label="在线" value="Y" />
                        <el-option label="离线" value="N" />
<!--                        <el-option-->
<!--                            v-for="item in $message.deviceStateList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.name"-->
<!--                            :value="item.id">-->
<!--                        </el-option>-->
                    </el-select>
                </el-form-item>
            </el-form>

            <el-table
                ref="deviceTable"
                class="device_table"
                :data="deviceMsg.list"
                border
                :height="tableHeight"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">

                <el-table-column
                    min-width="180"
                    prop="deviceId"
                    label="设备ID">
                </el-table-column>

                <el-table-column
                    min-width="100"
                    prop="telephone"
                    label="绑定用户">
                </el-table-column>

                <el-table-column
                    label="类型">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.deviceTypeList, scope.row.type, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="150"
                    prop="firstActiveTime"
                    label="激活日期">
                </el-table-column>

                <el-table-column
                    label="激活状态">
                    <template slot-scope="scope">
                        {{ $config.getMsgItemUtil($message.deviceStateList, scope.row.status, 'id', 'name') }}
                    </template>
                </el-table-column>

                <el-table-column
                    label="在线状态">
                    <template slot-scope="scope">
                        <span class="status off-line" v-if="scope.row.isOnline === 'N'">离线</span>
                        <span class="status on-line" v-else>在线</span>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination
                background
                :current-page="page"
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(deviceMsg.totalRecords)">
            </el-pagination>

        </el-card>
        <!-- 设备列表 start -->


    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {

    name: "deviceManagement",

    data() {

        return {

            //授权证书概览
            overview: {
                //授权数
                totalActivation: 0,
                //量产
                totalBatchProductionAuthorization: 0,
                //调试
                totalDebuggingAuthorization: 0
            },

            //表格高度
            tableHeight: 50,

            //当前页
            page: 1,

            loading: false,

            //授权申请数据
            deviceMsg: {
                list: [],
                totalRecords: 10
            },

            selectKey: 1,

            //查询表单条件
            form: {
                //设备ID/绑定用户
                keyword: "",
                //时间区间
                time: "",
                //开始时间
                startTime: "",
                //结束时间
                endTime: "",
                //地区
                // region: "",
                //设备状态
                status: "",
                type: ""
            }

        }

    },

    mounted() {

        setTimeout(() => {
            this.setHeight()
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
    },

    methods: {

        ...mapActions('device', ['queryDevice']),

        getMsg(page) {
            if (page) this.page = page;
            this.loading = true;
            this.queryDevice({
                currPage: this.page,
                pageSize: 10,
                pid: this.$route.query.pid,
                isOnline: this.form.status,
                startActiveTime: this.form.startTime,
                endActiveTime: this.form.endTime,
                type: this.form.type,
                deviceId: this.selectKey === 2 ? this.form.keyword : null,
                telephone: this.selectKey === 1 ? this.form.keyword : null
            }).then(res => {
                this.deviceMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        timeChange(val) {
            this.form.startTime = val ? val[0] : '';
            this.form.endTime = val ? val[1] : '';
            console.log(this.form);
            this.getMsg(1);
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = window.innerHeight - 450;
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        }

    }


}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 20px;
}

.clearfix {
    text-align: left;
}

/deep/ .el-card__header {
    padding: 10px;
}

.device_title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.60);
}

.device_msg {
    margin-top: 5px;
}

.device_msg_number {
    font-family: HelveticaNeue;
    font-size: 24px;
    color: #1890FF;
    margin-right: 3px;
}


/deep/ .el-date-editor {
    width: 350px;
}

.el-select {
    width: 100px;
}

/deep/ .el-table__body .el-table__row.hover-bg td{
    background-color: #DEE0E5 !important;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

.el-table {
    border-radius: 8px;
}

.status {
    width: auto;
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
}

.off-line {
    border: 1px solid #757575;
    color: #757575;
}

.on-line {
    border: 1px solid #389E0D;
    color: #389E0D;
}

/deep/ .el-date-editor {
    width: 380px;
}
</style>
