<template>
    <div>
        <el-form
            label-position="left"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="login-container">
            <el-form-item prop="username">
                <el-input
                    type="text"
                    v-model.trim="ruleForm.username"
                    auto-complete="off"
                    placeholder="请输入手机号">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="code">
                <el-input v-model.trim="ruleForm.code" placeholder="验证码"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    :type="isShowPwd1 ? 'text' : 'password'"
                    v-model.trim="ruleForm.password"
                    auto-complete="off"
                    :maxlength="32"
                    placeholder="密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd1 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd1 = !isShowPwd1"></i>
                </el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword" :error="error">
                <el-input
                    :type="isShowPwd2 ? 'text' : 'password'"
                    v-model.trim="ruleForm.confirmPassword"
                    auto-complete="off"
                    :maxlength="32"
                    placeholder="确认密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd2 ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd2 = !isShowPwd2"></i>
                </el-input>
            </el-form-item>

            <el-form-item>
                <el-checkbox class="remember" v-model="rememberPwd">
                    <span>
                        已阅读并同意
                        <el-link type="primary" href="#/protocol?type=fwxy">《用户协议》</el-link>
                        和
                        <el-link type="primary" href="#/protocol?type=ysxy">《隐私政策》</el-link>
                    </span>
                </el-checkbox>
            </el-form-item>
            <el-form-item style="width: 100%">
                <el-button
                    type="primary"
                    style="width: 100%;"
                    :loading="registering"
                    @click="register"
                >注册
                </el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    data() {

        //再次输入密码验证
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
                // password 是表单上绑定的字段
            } else if (value !== this.ruleForm.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }

        return {

            //定义loading默认为false
            registering: false,

            rememberPwd: false,

            isShowPwd1: false,

            isShowPwd2: false,

            ruleForm: {
                //username和password默认为空
                username: "",
                password: "",
                confirmPassword: "",
                code: "",
                smsId: ""
            },

            time: 60,

            isCodeBtnDisabled: false,

            codeBtnLoading: false,

            codeBtnMsg: "获取验证码",

            phoneErr: "",

            //rules前端验证
            rules: {
                username: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {pattern: /^0?1[0-9]{10}$/, message: '手机号格式不正确'}
                ],
                password: [
                    {required: true, message: "请输入密码", trigger: "blur"},
                    {pattern: /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~#^])[A-Za-z\d@$!%*?&~#^]{8,}$/, message: "必须包含大小写字母、数字、特殊字符、长度不小于8"}
                ],
                confirmPassword: [{required: true, validator: validatePass2}],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
            },

            error: ""

        }

    },

    methods: {

        ...mapActions('login', ['sendSms', 'isAllowRegistration']),

        /**
         * 重置表单
         */
        initForm() {
            this.phoneErr = "";
            this.error = ""
            this.$refs.ruleForm.resetFields();

            this.ruleForm = {
                //username和password默认为空
                username: "",
                password: "",
                confirmPassword: "",
                code: "",
                smsId: ""
            }
        },

        /**
         * 注册
         */
        register() {
            let isStart = true;

            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    if (!this.rememberPwd) {
                        this.$dialog.showMessage("请先勾选阅读协议", this.$config.TOAST_WARNING);
                        return
                    }
                    this.registering = true;
                    this.error = "";
                    this.$store.dispatch("login/register", {
                        password: this.$md5(this.ruleForm.password),
                        platformType: "2",
                        userName: this.ruleForm.username,
                        smsId: this.ruleForm.smsId,
                        channel: "2",
                        code: this.ruleForm.code
                    }).then(res => {
                        this.registering = false;
                        this.$dialog.showMessage("注册成功！", this.$config.TOAST_SUCCESS);
                        this.initForm();
                        this.$emit('registerSuccess');
                        console.log(res);
                    }, err => {
                        this.registering = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        },

        /**
         * 获取验证码
         */
        async getCode() {
            if (!this.ruleForm.username) {
                this.phoneErr = "请输入手机号"
                return
            }
            if (!this.$config.phoneRight(this.ruleForm.username)) {
                this.phoneErr = "手机号格式不正确"
                return;
            }

            let isRegistred = false;
            await this.isAllowRegistration({
                platformType: 2,
                userName: this.ruleForm.username
            }).catch(err => {
                isRegistred = true;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
            if (isRegistred) {
                return
            }

            this.phoneErr = ""
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.ruleForm.username,
                type: '1'
            }).then(res => {
                this.ruleForm.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        /**
         * 设置验证码倒计时
         */
        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        }

    }

}
</script>

<style scoped>
.login-container {
    width: 80%;
    margin: 5% auto;
}

.text-link {
    color: #1C204F;
}

.remember {
    float: left;
    margin-bottom: -23px;
    margin-top: -10px;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

/deep/ .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}
</style>
