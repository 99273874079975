<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            设备调试
        </div>
        <!-- 标题 end -->

        <!-- 设备详情 start -->
        <div class="device_msg">
            <div class="device_list">
                <div class="device_top">
                    <div class="device_item"><span class="device_item_title">设备ID：</span>{{ deviceData.deviceId }}</div>

                    <el-dropdown size="small" @command="handleCommand" trigger="click">
                        <el-button class="device_edit" size="mini" plain type="primary">
                            切换设备<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(item, index) in productList"
                                :command="item"
                                :class="deviceData.code === item.code ? 'company_list_item_activate':''"
                                :disabled="deviceData.code === item.code"
                                :key="index">
                                {{ item.deviceId }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="device_bottom">
                    <div class="device_item"><span class="device_item_title">绑定用户：</span>{{ deviceData.userId }}</div>
                </div>
                <div class="device_bottom">
                    <div class="device_item">
                        <span class="device_item_title">设备状态：</span>
                        <div :class="deviceData.isConnect ? 'state' : 'state_close'">
                            {{ deviceData.isConnect ? '在线' : '离线' }}
                        </div>
                        <div :class="deviceData.isConnect ? 'spot' : 'spot_close'"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 设备详情 end -->

        <!-- 调试卡片 start -->
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '16px',
                height: cardHeight + 'px'
            }"
            shadow="never">

            <div slot="header" class="clearfix">
                <span>功能调试</span>
                <el-button size="mini" type="primary" plain style="float: right;margin-top: -4px;" @click="getMsg()">获取所有属性</el-button>
            </div>


            <el-table
                ref="effectTable"
                class="effect_table"
                border
                :span-method="objectSpanMethod"
                :data="effectList"
                :height="cardHeight - 16"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                :row-class-name="tableRowClassName"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)'}"
                v-loading="loading"
                :style="{
                    width: '100%'
                }">

                <el-table-column
                    width="50"
                    prop="sid"
                    label="SID">
                </el-table-column>

                <el-table-column
                    width="200"
                    prop="serviceName"
                    label="功能名称">
                </el-table-column>

                <el-table-column
                    width="50"
                    prop="aid"
                    label="AID">
                </el-table-column>

                <el-table-column
                    width="200"
                    prop="attributeName"
                    label="属性名称">
                </el-table-column>

                <el-table-column
                    width="250"
                    label="属性值">
                    <template slot-scope="scope">
                        <el-input
                            v-if="scope.row.type === 'String' || scope.row.type === 'Raw'"
                            type="textarea" v-model="scope.row.value" placeholder=""
                            size="mini"></el-input>
                        <el-input-number v-if="scope.row.type === 'Number'" controls-position="right"
                                         v-model="scope.row.value" :min="scope.row.min"
                                         :step="scope.row.step" :max="scope.row.max"
                                         size="mini" step-strictly></el-input-number>
                        <el-select v-if="scope.row.type === 'Boolean'"
                                   v-model="scope.row.value" placeholder="请选择" size="mini">
                            <el-option label="true" :value="true"></el-option>
                            <el-option label="false" :value="false"></el-option>
                        </el-select>
                        <el-select
                            v-if="scope.row.type === 'Enum' || scope.row.type === 'Fault'"
                            v-model="scope.row.value" placeholder="请选择" size="mini">
                            <el-option
                                v-for="item1 in scope.row.list"
                                :key="item1.id"
                                :label="item1.name"
                                :value="item1.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column
                    width="110"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   :disabled="scope.row.authority === 1"
                                   @click="getAttr(scope.row)"
                                   :loading="scope.row.getLoading">获取
                        </el-button>
                        <el-button type="text" size="small"
                                   :disabled="scope.row.authority === 2"
                                   @click="setAttr(scope.row)"
                                   :loading="scope.row.setLoading">设置
                        </el-button>
<!--                        <el-button :disabled="scope.row.authority === 1 || !deviceData.isConnect" type="text" size="small"-->
<!--                                   :loading="scope.row.getLoading">获取-->
<!--                        </el-button>-->
<!--                        <el-button :disabled="scope.row.authority === 2 || !deviceData.isConnect" type="text" size="small"-->
<!--                                   :loading="scope.row.setLoading">设置-->
<!--                        </el-button>-->
                    </template>
                </el-table-column>

                <el-table-column
                    prop="logValue"
                    label="内容">
                </el-table-column>


            </el-table>

        </el-card>
        <!-- 调试卡片 end -->

    </div>
</template>

<script>
import {mapActions} from "vuex";
import MQTT from "../../utils/mqtt";

export default {

    name: "newDeviceDebug",

    data() {

        return {

            deviceData: {
                //设备ID
                deviceId: "",
                code: "",
                //绑定用户
                userId: "",
                //设备是否在线
                isConnect: true
            },

            cardHeight: 300,

            effectList: [],

            hoverIndex: null,

            loading: false,

            //mqtt对象
            mqtt: null,

            productList: [],

            //是否是初始化mqtt
            isFirst: true

        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.deviceData.deviceId = this.$route.query.deviceId;
        this.deviceData.code = this.$route.query.code;
        this.deviceData.userId = this.$route.query.telephone;
        this.getRealMsg();
        this.getMsg();
    },

    methods: {

        ...mapActions('device', ['getAllAttributes', 'getAttributes', 'setAttributes', 'queryDebugDevicePager']),
        ...mapActions('effect', ['queryProductServiceList']),

        initMQTT() {
            let userId = window.localStorage.getItem('userId');
            this.mqtt = new MQTT({
                clientId: `W-I-${userId}-${this.$config.randomString(5)}`,
                username: userId,
                password: this.$token.getToken().accessToken,
                clean: true,
                connectTimeout: 4000,
                reconnectPeriod: 1000
            }, (isSuccess, msg) => {
                if (!isSuccess) {
                    this.$dialog.showMessage("MQTT连接失败！", this.$config.TOAST_ERROR);
                    console.error(msg);
                    return;
                }
                if (isSuccess && this.isFirst) {
                    this.isFirst = false;
                    this.mqtt.mqttReceive(this.onMQTTMsg, [
                        `b_rptattr/+/+/${this.deviceData.deviceId}`,
                        `b_r_setattr/+/+/${this.deviceData.deviceId}/+`,
                        `b_status/+/${this.deviceData.deviceId}`
                    ])
                }
            });
        },

        /**
         * mqtt回调监听
         * @param isSendResponse
         * @param msg
         */
        onMQTTMsg(topic, msg) {
            console.log('上报结果 => ', topic + "=>", this.$config.format(new Date().getTime(), true), msg);
            if (topic.indexOf('b_rptattr') !== -1) {
                this.setMsgToTable(JSON.parse(msg.body.data));
            } else if (topic.indexOf('b_r_setattr') !== -1) {
                let serviceKey = msg.body.serviceKey;
                this.effectList.forEach(item => {
                    if (item.serviceKey === serviceKey) {
                        item.logValue = JSON.stringify(msg.body);
                    }
                })
            } else if (topic.indexOf("b_status") !== -1) {
                this.deviceData.isConnect = msg.body.isOnline
            }
        },

        getAllAttr() {
            this.getAllAttributes({
                deviceId: this.$route.query.deviceId
            }).then(res => {
                this.setMsgToTable(res.result);
                //初始化MQTT
                this.initMQTT();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        getAttr(row) {
            row.getLoading = true;
            this.getAttributes({
                deviceId: this.$route.query.deviceId,
                serviceKey: row.serviceKey
            }).then(res => {
                if (!res.result) {
                    this.$dialog.showMessage("设备未上报数据！", this.$config.TOAST_WARNING);
                    return
                }
                this.$dialog.showMessage("获取成功！", this.$config.TOAST_SUCCESS);
                this.setMsgToTable(res.result)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                row.getLoading = false;
            })
        },

        setAttr(row) {
            row.setLoading = true;
            let attrs = [];
            let isStop = false;
            for (let i = row.number; i < (row.number + row.rowspan); i++) {
                attrs.push({
                    k: this.effectList[i].attributeKey,
                    v: this.effectList[i].value
                });

                if (
                    (
                        this.effectList[i].type === "Boolean" ||
                        this.effectList[i].type === "Boolean" ||
                        this.effectList[i].type === "Enum" ||
                        this.effectList[i].type === "Fault" ||
                        this.effectList[i].type === "Integer" ||
                        this.effectList[i].type === "Short" ||
                        this.effectList[i].type === "Long" ||
                        this.effectList[i].type === "Float"
                    ) &&
                    (
                        this.effectList[i].value === "" ||
                        this.effectList[i].value === undefined
                    )
                ) {
                    isStop = true;
                    row.setLoading = false;
                    this.$dialog.showMessage("请填写" + this.effectList[i].attributeName, this.$config.TOAST_WARNING);
                    return;
                }
            }
            if (isStop) return;
            let data = {
                k: row.serviceKey,
                attrs
            };
            this.setAttributes({
                deviceId: this.$route.query.deviceId,
                instruction: JSON.stringify(data)
            }).then(res => {
                this.$dialog.showMessage((res.code === "0" ? "下发成功" : res.resultMsg) + `  状态码：${res.code}`, res.code === "0" ? this.$config.TOAST_SUCCESS : this.$config.TOAST_ERROR);
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg + `  状态码：${err.code}`, this.$config.TOAST_ERROR);
            }).finally(() => {
                row.setLoading = false;
            })
        },

        setMsgToTable(msg) {
            if (!msg) return;
            let msgList = [];
            msg.forEach(item => {
                item.attrs.forEach(item1 => {
                    msgList.push({
                        sk: item.k,
                        ak: item1.k,
                        v: item1.v,
                        itemLog: item
                    })
                })
            })
            msgList.forEach(item => {
                this.effectList.forEach(item1 => {
                    if (item.sk === item1.serviceKey && item.ak === item1.attributeKey) {
                        item1.value = item.v;
                        // item1.logValue = JSON.stringify(item.itemLog);
                    }
                })
            })
        },

        getRealMsg() {
            this.queryDebugDevicePager({
                currPage: 1,
                pageSize: 10000,
                pid: this.$route.query.pid
            }).then(res => {
                this.productList = res.result.list;
                this.productList.forEach(item => {
                    if (this.deviceData.deviceId === item.deviceId) {
                        //获取设备在线状态
                        this.deviceData.isConnect = item.isOnline === "Y";
                    }
                })
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.realLoading = false;
            })
        },

        /**
         * 处理格式化功能定义数据
         * @param {*} data
         */
        effectDataHandle(data) {
            let list = this.$config.deepCopy(data);
            console.log(list)
            let effectList = [];
            list.forEach(item => {
                item.productAttributeList.forEach((item1, index1) => {
                    let list1 = [];
                    if (item1.dataType === 3 || item1.dataType === 7) {
                        let enumList = JSON.parse(item1.enumValues);
                        for (let j = 0; j < enumList.length; j++) {
                            for (let key in enumList[j]) {
                                if (key !== 'id') {
                                    list1.push({
                                        id: key,
                                        name: enumList[j][key]
                                    })
                                }
                            }
                        }
                    }
                    effectList.push({
                        list: list1,
                        number: effectList.length,
                        code: item.code,
                        attributeCode: item1.code,
                        aid: item1.attributeId,
                        serviceName: item.name,
                        serviceKey: item.serviceKey,
                        effectType: item.type,
                        attributeName: item1.name,
                        attributeKey: item1.attributeKey,
                        authority: item.authority,
                        format: item1.dataType,
                        type: this.$config.getMsgItemUtil(this.$message.formatIDList, item1.dataType, 'id', 'name'),
                        rowspan: item.productAttributeList.length > 1 ? (index1 == 0 ? item.productAttributeList.length : 0) : 1,
                        colspan: index1 == 0 ? 1 : 0,
                        endNum: item1.maxIntValue,
                        startNum: item1.minIntValue,
                        //最大值
                        max: parseInt(item1.maxValue),
                        //最小值
                        min: parseInt(item1.minValue),
                        //步长
                        step: parseInt(item1.stepValue),
                        unit: item1.unit,
                        string: item1.maxLength,
                        sid: item.serviceId,
                        value: (item1.dataType === 1 || item1.dataType === 2) ? item1.minIntValue ? parseInt(item1.minIntValue) : undefined : "",
                        getLoading: false,
                        setLoading: false,
                        logValue: "—"
                    })
                })
            });
            console.log(effectList)
            return effectList;
        },

        /**
         * 获取自定义产品功能列表
         */
        getMsg() {
            this.loading = true;
            this.queryProductServiceList({
                productCode: this.$route.query.id
            }).then(res => {
                if (!res.result) {
                    this.loading = false;
                    return
                }
                this.effectList = this.effectDataHandle(res.result);
                this.getAllAttr();
            }, err => {
                this.loading = false;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },


        handleCommand(command) {
            this.$config.changeURLArg('deviceId', command.deviceId)
            this.$config.changeURLArg('code', command.code)
            this.$router.go(0);
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 370;
        },

        /**
         * 设置列表合并
         * @param row 行数据
         * @param columnIndex 行数据列号
         * @returns {{colspan: (string|*), rowspan: (string|*)}}
         */
        objectSpanMethod({row, columnIndex}) {
            if (columnIndex == 0 || columnIndex == 1 || columnIndex == 5 || columnIndex == 6) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.colspan,
                };
            }
        },

        tableRowClassName({row}) {
            if (this.hoverIndex === row.code) {
                return "hover-bg";
            }
        },

        /**
         * ⿏标移⼊表格行
         * @param row
         * @param column
         * @param cell
         * @param event
         */
        cellMouseEnter(row, column, cell, event) {
            this.hoverIndex = row.code;
        },

        /**
         * ⿏标移出表格行
         */
        cellMouseLeave() {
            this.hoverIndex = null;
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
        this.mqtt.closeMQTT();
    }

}
</script>

<style scoped>
.device_msg {
    height: 120px;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 24px 24px;
}

.device_list {
    height: 72px;
    margin: 10px 20px;
    float: left;
    text-align: left;
}

.device_top {
    height: 22px;
    padding: 7px 0 0 0;
}

.device_bottom {
    height: 22px;
    margin-top: 10px;
}

.device_item {
    float: left;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}

.device_item_title {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}

.device_edit {
    float: left;
    margin-left: 21px;
    margin-top: -2px;
    height: 22px;
    padding: 3px;
}

.el-table {
    border-radius: 8px;
}

.spot {
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 15px;
    background-color: #52C41A;
    margin-left: 5px;
    float: right;
}

.state {
    font-weight: 400;
    font-size: 12px;
    color: #52C41A;
    float: right;
    margin-left: 5px;
    margin-top: 1px;
}

.spot_close {
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 15px;
    background-color: #757575;
    margin-left: 5px;
    float: right;
}

.state_close {
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    float: right;
    margin-left: 5px;
    margin-top: 1px;
}

.clearfix {
    text-align: left;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 0 24px;
    padding: 0;
}

/deep/ .el-card__header {
    padding: 10px 16px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-table__body .el-table__row.hover-bg td {
    background-color: #DEE0E5 !important;
}

.el-input-number, .el-select {
    width: 229px;
}
</style>
