<template>
    <div>
        <el-form
            label-position="left"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="0px"
            class="login-container"
        >
            <el-form-item prop="username">
                <el-input
                    type="text"
                    ref="username"
                    v-model.trim="ruleForm.username"
                    auto-complete="off"
                    placeholder="请输入手机号">
                    <template slot="append" v-if="isTelLogin">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="code" v-if="isTelLogin">
                <el-input
                    v-model.trim="ruleForm.code"
                    placeholder="验证码">
                </el-input>
            </el-form-item>
            <el-form-item prop="password" v-else>
                <el-input
                    :type="isShowPwd ? 'text' : 'password'"
                    v-model.trim="ruleForm.password"
                    auto-complete="off"
                    placeholder="密码">
                    <i slot="suffix" :class="'pwd-icon ' + (isShowPwd ? 'icon-buxianzhi' : 'icon-xianzhi')"
                       @click="isShowPwd = !isShowPwd"></i>
                </el-input>

            </el-form-item>

            <el-form-item style="margin-bottom:10px;">
                <el-row style="margin-top: -10px;">
                    <el-col :span="12" style="text-align: left;">
                        <el-button type="text" @click="showReset">忘记密码？</el-button>
                    </el-col>
                    <el-col :span="12" style="text-align: right;">
                        <el-button type="text" @click="isTelLogin = !isTelLogin">{{ !isTelLogin ? "手机号登录" : "密码登录" }}
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-checkbox class="remember" v-model="rememberpwd">
                    <span>
                        已阅读并同意
                        <el-link type="primary" href="#/protocol?type=fwxy" target="_blank">《用户协议》</el-link>
                        和
                        <el-link type="primary" href="#/protocol?type=ysxy" target="_blank">《隐私政策》</el-link>
                    </span>
                </el-checkbox>
            </el-form-item>
            <el-form-item style="width: 100%">
                <el-button
                    type="primary"
                    style="width: 100%;"
                    :loading="logining"
                    @click="login()"
                >登录
                </el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('login', ['menuData'])
    },

    watch: {
        //菜单列表
        menuData: {
            handler(newVal) {
                setTimeout(() => {
                    this.logining = false;
                    //判断是否有菜单权限
                    let menu = newVal.homeMenuList.concat(newVal.settingMenuList)
                    this.$router.push({path: menu.length == 0 ? "/403" : "/main"});
                })
            },
            deep: true
        }
    },

    data() {
        return {

            isTelLogin: false,

            //定义loading默认为false
            logining: false,

            rememberpwd: false,

            codeBtnLoading: false,

            ruleForm: {
                //username和password默认为空
                username: "",
                password: "",
                code: "",
                smsId: ""
            },

            isShowPwd: false,

            phoneErr: "",

            time: 60,

            isCodeBtnDisabled: false,

            codeBtnMsg: "获取验证码",

            //rules前端验证
            rules: {
                username: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {pattern: /^1[0-9]{10}$/, message: '手机号格式不正确'}
                ],
                password: [{required: true, message: "请输入密码", trigger: "blur"}],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
            }

        }

    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.username.focus();
        })
    },

    methods: {

        ...mapActions('login', ['getUserAuthorityTree', 'sendSms']),

        /**
         * 清空表单
         */
        initForm() {
            this.phoneErr = "";
            this.$refs.ruleForm.resetFields();
        },

        /**
         * 打开重置密码弹窗
         */
        showReset() {
            this.$store.commit("login/SET_RESET_PASSWORD_DIALOG_VISIBLE", true);
        },

        /**
         * 重置密码
         */
        login() {
            let isStart = true;
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    if (!this.rememberpwd) {
                        this.$dialog.showMessage("请先勾选阅读协议", this.$config.TOAST_WARNING);
                        return
                    }
                    this.logining = true
                    this.$store.dispatch("login/login", {
                        password: this.isTelLogin ? this.ruleForm.code : this.$md5(this.ruleForm.password),
                        platformType: "2",
                        tid: "2",
                        smsId: this.ruleForm.smsId,
                        type: this.isTelLogin ? '2' : '1',
                        userName: this.ruleForm.username,
                        vcode: this.ruleForm.code
                    }).then(res => {
                        console.log(res);
                        this.$token.setToken(res.result.accessToken, res.result.refreshToken);
                        this.getUserAuthorityTree();
                    }, err => {
                        this.logining = false;
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        },

        /**
         * 获取验证码
         */
        getCode() {
            if (!this.$config.phoneRight(this.ruleForm.username)) {
                this.phoneErr = "手机号格式不正确"
                return;
            }
            if (!this.ruleForm.username) {
                this.phoneErr = "请输入手机号"
                return
            }
            this.phoneErr = ""
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.ruleForm.username,
                type: '2'
            }).then(res => {
                this.ruleForm.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        /**
         * 获取验证码后设置倒计时
         */
        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            let codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(codeTime);
                }
            }, 1000)
        }

    }

}
</script>

<style scoped>
.login-container {
    width: 80%;
    margin: 10% auto;
}

.pwd-icon {
    font-size: 14px;
    color: #757575;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    cursor: pointer;
}

.text-link {
    color: #1C204F;
}

.remember {
    float: left;
    margin-bottom: -23px;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}

/deep/ .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
}
</style>
