<template>
    <el-drawer
        title="角色授权"
        :visible.sync="visible"
        direction="rtl"
        :size="400"
        v-loading="loading"
        @open="handleOpen"
        @close="closeNoticeMsgDialog">
        <el-divider></el-divider>
        <el-checkbox v-model="isSelectAll" class="all" @change="checkAllChange">全选</el-checkbox>
        <el-tree
            ref="channelTree"
            class="role_tree"
            :props="props"
            node-key="code"
            :data="menuData"
            check-strictly
            default-expand-all
            :default-checked-keys="checkedKeys"
            show-checkbox
            @check="handleCheckChange">
        </el-tree>
    </el-drawer>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
    computed: {
        ...mapState('roleManage', ['empowerDrawerVisible', 'roleMsgItem']),
        ...mapState('menu', ['menuList'])
    },
    watch: {
        empowerDrawerVisible: {
            handler(newVal) {
                console.log(newVal)
                this.visible = newVal;
            }
        },
        menuList: {
            handler(newVal) {
                let codeList = [];
                console.log(newVal)
                this.menuData = [...newVal.homeMenuList, ...newVal.settingMenuList]
                codeList = this.getTreeCode(this.menuData);
                this.codeList = codeList;
                console.log(this.menuData, codeList)
            }
        }
    },
    data() {
        return {
            isSelectAll: false,
            loading: false,
            loadNode: false,
            visible: false,
            menuData: [],
            codeList: [],
            checkedKeys: [],
            props: {
                children: "children",
                label: "name"
            }
        }
    },
    methods: {
        ...mapActions("roleManage", ["closeEmpowerDrawer", "getRoleauthorityList"]),
        ...mapActions('menu', ['getUserAuthorityTree']),
        handleOpen() {
            this.getUserAuthorityTree();
            this.getRoleauthorityList({
                roleCode: this.roleMsgItem.code
            }).then(res => {
                let menuCodeList = [];
                if (!res.result) {
                    this.isSelectAll = (this.checkedKeys.length == this.codeList.length) && this.checkedKeys.length > 0;
                    return;
                }
                for (let i = 0; i < res.result.length; i++) {
                    menuCodeList.push(res.result[i].authorityCode);
                }
                this.checkedKeys = menuCodeList;
                this.isSelectAll = (this.checkedKeys.length == this.codeList.length) && this.checkedKeys.length > 0;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', this.$config.TOAST_ERROR);
            })
        },
        closeNoticeMsgDialog() {
            this.closeEmpowerDrawer();
            this.checkedKeys = [];
        },
        getTreeCode(list) {
            let codeList = []
            for (let i = 0; i < list.length; i++) {
                codeList.push(list[i].code);
                if (list[i].children && list[i].children.length > 0) {
                    codeList = codeList.concat(this.getTreeCode(list[i].children));
                }
            }
            return codeList;
        },
        handleCheckChange(data, checked) {
            console.log(data, checked);
            let isSelect = checked.checkedKeys.indexOf(data.code) != -1;
            this.setAuth(isSelect, false, data)
        },
        setAuth(isSelect, isBatch, data) {
            this.loading = true;
            this.$store.dispatch("roleManage/" + (isSelect ? "pitchonauthorityRoleauthority" : "pitchoffauthorityRoleauthority"), {
                authorityCode: isBatch ? null : data.code,
                roleCode: this.roleMsgItem.code,
                isAll: isBatch ? "Y" : "N",
                authorityCodes: isBatch ? this.codeList.join(",") : null
            }).then(res => {
                this.$dialog.showMessage('操作成功', this.$config.TOAST_SUCCESS);

                if (isBatch) {
                    if (isSelect) {
                        this.checkedKeys = this.$config.deepCopy(this.codeList)
                    } else {
                        this.checkedKeys = []
                    }
                    this.$nextTick(() => {
                        this.codeList.forEach(item => {
                            this.$refs.channelTree.setChecked(item, isSelect, false);
                        })
                    })
                } else {
                    this.setCheckedKeys(isSelect, data.code);
                    this.$nextTick(() => {
                        this.$refs.channelTree.setChecked(data.code, isSelect, false);
                    })
                    if (!isSelect) {
                        this.isSelectAll = false;
                    } else {
                        this.isSelectAll = (this.checkedKeys.length == this.codeList.length) && this.checkedKeys.length > 0;
                    }
                }
            }, err => {
                console.log(err);
                if (isBatch) {
                    this.isSelectAll = !this.isSelectAll;
                } else {
                    this.$nextTick(() => {
                        this.$refs.channelTree.setChecked(data.code, !isSelect, false);
                    })
                }
                this.$dialog.showMessage(err.resultMsg ? err.resultMsg : '请求错误', this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        setCheckedKeys(isSelect, code) {
            if (isSelect) {
                this.checkedKeys.push(code);
            } else {
                this.checkedKeys.forEach((item, index) => {
                    if (item == code) {
                        this.checkedKeys.splice(index, 1);
                        return;
                    }
                })
            }
        },

        checkAllChange() {
            this.setAuth(this.isSelectAll, true)
        }
    },
}
</script>

<style scoped>
/deep/ .demo-drawer__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;
    width: 400px;
    text-align: right;
    background-color: white;
}

/deep/ .el-drawer__header {
    font-weight: bold !important;
    text-align: left;
    color: black !important;
    padding: 10px;
    margin-bottom: 0px;
}

/deep/ .el-drawer__body {
    padding: 0 10px;
}

.role_form {
    margin-top: 18px;
}

/deep/ .el-divider--horizontal {
    margin: 0px;
}

.role_tree {
    margin-top: 5px;
}

.all {
    margin-left: 24px;
    margin-top: 20px;
}
</style>
