var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticStyle:{"height":"100%"}},[_c('el-button',{staticClass:"document_center",attrs:{"type":"text","icon":"el-icon-tickets"},on:{"click":function($event){return _vm.openDoc()}}},[_vm._v("文档中心")]),_c('img',{staticClass:"login_left",attrs:{"src":require("../../assets/login_left.png")},on:{"load":_vm.loadImage}}),(_vm.isShowRight)?_c('div',{staticClass:"login_right",style:({
            width: 'calc(95% - ' + _vm.loginLeftWidth + 'px)',
            textAlign: 'center'
        })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadEnd),expression:"isLoadEnd"}],staticClass:"card-form",style:({
                height: _vm.activeName == 'register' ? '400px' : '360px',
                margin: `${_vm.activeName == 'register' ? '-200px' : '-170px'} auto`
            })},[_c('el-tabs',{on:{"tab-click":_vm.switchTab},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"登录","name":"login"}},[_c('login',{ref:"login"})],1),_c('el-tab-pane',{attrs:{"label":"注册","name":"register"}},[_c('register',{ref:"register",on:{"registerSuccess":_vm.registerSuccess}})],1)],1)],1),_c('el-link',{staticClass:"copyright",style:({marginLeft: -_vm.loginLeftWidth/2 + 'px'}),attrs:{"type":"info","target":"_blank","href":"https://beian.miit.gov.cn"}},[_vm._v("Copyright   ©2022   广州朗国电子科技有限公司    粤ICP备14024048号")])],1):_vm._e()],1),_c('reset-password-dialog'),_c('set-password-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }