<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="更换手机号"
        :visible.sync="visible"
        width="25%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-form :model="form1" size="small" :rules="rules1" ref="ruleForm1" v-show="isFirst">
            <el-form-item>
                验证账号：{{ form1.tel }}
            </el-form-item>
            <el-form-item prop="code">
                <el-input
                    type="text"
                    v-model.trim="form1.code"
                    placeholder="验证码">
                    <template slot="append">
                        <el-button class="code_btn" @click="getUserCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>
        </el-form>
        <el-form :model="form" size="small" :rules="rules" ref="ruleForm" v-show="!isFirst">
            <el-form-item :error="phoneErr">
                <el-input
                    type="text"
                    v-model.trim="form.username"
                    placeholder="请输入手机号">
                    <template slot="append">
                        <el-button class="code_btn" @click="getCode" :loading="codeBtnLoading"
                                   :disabled="isCodeBtnDisabled">{{ codeBtnMsg }}
                        </el-button>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item prop="code">
                <el-input
                    v-model.trim="form.code"
                    placeholder="验证码">
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog">取 消</el-button>
			<el-button type="primary" :loading="loading" @click="next" v-if="isFirst">下一步</el-button>
			<el-button type="primary" :loading="loading" @click="submit" v-else>确 认</el-button>
		</span>
    </el-dialog>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {

    computed: {
        ...mapState('setting', ['updateTelDialogVisible']),
        ...mapState('login', ['userInfo'])
    },

    watch: {

        updateTelDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {
        return {
            loading: false,
            form1: {
                tel: "",
                code: "",
                smsId: ""
            },
            form: {
                username: "",
                code: "",
                smsId: ""
            },
            codeTime: null,
            isFirst: true,
            codeBtnLoading: false,
            time: 60,
            isCodeBtnDisabled: false,
            codeBtnMsg: "获取验证码",
            visible: false,
            phoneErr: "",
            rules: {
                // username: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}]
            },
            rules1: {
                code: [{required: true, message: "请输入验证码", trigger: "blur"}]
            }
        }
    },

    methods: {

        ...mapActions('setting', ['closeUpdateTelDialog']),

        ...mapActions('login', ['sendSms', 'modifyPhone', 'sendUserSms', 'checkUserPhone', 'getlogininfo', 'isAllowRegistration']),

        openDialog() {
            console.log(this.userInfo)
            console.log(this.form1)
            this.form1.tel = this.userInfo.telephone;
        },

        closeDialog() {
            this.closeUpdateTelDialog();
            this.$refs.ruleForm.resetFields();
            this.$refs.ruleForm1.resetFields();
            this.isFirst = true;
            this.form1 = {
                tel: "",
                code: "",
                smsId: ""
            };
            this.form = {
                username: "",
                code: "",
                smsId: ""
            };
        },

        submit() {
            if (!this.form.smsId) {
                this.$dialog.showMessage("请先获取验证码", this.$config.TOAST_WARNING);
                return
            }
            let isStart = true;
            if (!this.$config.phoneRight(this.form.username)) {
                this.phoneErr = "手机号格式不正确"
                isStart = false;
            }
            if (!this.form.username) {
                this.phoneErr = "请输入手机号"
                isStart = false;
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid && isStart) {
                    this.loading = true;
                    this.modifyPhone({
                        code: this.form.code,
                        newPhone: this.form.username,
                        oldCode: this.form1.code,
                        oldSmsId: this.form1.smsId,
                        smsId: this.form.smsId
                    }).then(res => {
                        this.$dialog.showMessage("修改成功", this.$config.TOAST_SUCCESS);
                        this.closeDialog();
                        this.$emit('getUserInfo');
                        this.getlogininfo();
                    }, err => {
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        next() {
            if (!this.form1.smsId) {
                this.$dialog.showMessage("请先获取验证码", this.$config.TOAST_WARNING);
                return
            }
            this.loading = true;
            this.$refs.ruleForm1.validate((valid) => {
                if (valid) {
                    this.checkUserPhone({
                        code: this.form1.code,
                        smsId: this.form1.smsId
                    }).then(res => {
                        this.cleanCountDown();
                        this.isFirst = false;
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                        this.isFirst = true;
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            });
        },

        async getCode() {
            if (!this.form.username) {
                this.phoneErr = "请输入手机号"
                return
            }
            if (!this.$config.phoneRight(this.form.username)) {
                this.phoneErr = "手机号格式不正确"
                return;
            }

            let isRegistred = false;
            await this.isAllowRegistration({
                platformType: 2,
                userName: this.form.username
            }).catch(err => {
                isRegistred = true;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
            if (isRegistred) {
                return
            }

            this.phoneErr = ""
            this.codeBtnLoading = true;
            this.sendSms({
                phoneCode: this.form.username,
                type: '4'
            }).then(res => {
                this.form.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        getUserCode() {
            if (!this.form1.tel) {
                this.$dialog.showMessage("请输入手机号码", this.$config.TOAST_WARNING);
                return
            }
            this.codeBtnLoading = true;
            this.sendUserSms({
                type: '4'
            }).then(res => {
                this.form1.smsId = res.result;
                this.countDown();
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.codeBtnLoading = false;
            })
        },

        countDown() {
            this.isCodeBtnDisabled = true;
            this.codeBtnMsg = this.time + "s后重新获取"
            this.codeTime = setInterval(() => {
                this.time--;
                this.codeBtnMsg = this.time + "s后重新获取"
                if (this.time < 0) {
                    this.time = 60;
                    this.codeBtnMsg = "重新获取验证码"
                    this.isCodeBtnDisabled = false;
                    clearInterval(this.codeTime);
                }
            }, 1000)
        },

        cleanCountDown() {
            if (this.codeTime) {
                clearInterval(this.codeTime);
            }
            this.time = 60;
            this.codeBtnLoading = false;
            this.codeBtnMsg = "获取验证码";
            this.isCodeBtnDisabled = false;
        }

    }

}
</script>

<style scoped>
/deep/ .el-dialog {
    text-align: left !important;
}

/deep/ .el-dialog__body {
    padding-top: 0;
}

/deep/ .el-dialog__title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 13px;
}

/deep/ .el-dialog__footer {
    text-align: right;
}

.el-form {
    margin-top: 20px;
}

.code_btn {
    font-size: 10px;
    padding: 0 10px;
}
</style>
