import { render, staticRenderFns } from "./productTable.vue?vue&type=template&id=86fb2cce&scoped=true&"
import script from "./productTable.vue?vue&type=script&lang=js&"
export * from "./productTable.vue?vue&type=script&lang=js&"
import style0 from "./productTable.vue?vue&type=style&index=0&id=86fb2cce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86fb2cce",
  null
  
)

export default component.exports