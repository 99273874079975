<template>
    <el-submenu :index="item.code+''" v-if="item.children && item.children.length > 0">
        <template slot="title">
            <div slot="title" :class="'menu menu_'+item.code" @click="toMenu(item)">
                <i class="iconfont icon-chanpinkaifa" v-if="item.name === '产品开发'"></i>
                <i class="iconfont icon-shouquanfuwu" v-else-if="item.name === '授权服务'"></i>
                <i class="iconfont icon-qiyeguanli" v-if="item.name === '企业管理'"></i>
                <i class="iconfont icon-yunweifuwu" v-if="item.name === '运维服务'"></i>
                {{ item.name }}
            </div>
        </template>
        <tree-menu :item="item1" v-for="(item1,index) in item.children" :key="index"></tree-menu>
    </el-submenu>
    <el-menu-item :index="item.uri" @click="getMenuItem(item)" v-else>
        <i class="iconfont iconfont-item icon-gerenxinxi" style="left: -30px!important;" v-if="item.name === '个人信息'"></i>
        <i class="iconfont iconfont-item icon-qiyeguanli" style="left: -30px!important;" v-if="item.name === '企业管理'"></i>
        <span slot="title" style="margin-left: -23px!important;" v-if="item.name === '个人信息'">{{ item.name }}</span>
        <span slot="title" style="margin-left: -23px!important;" v-else-if="item.name === '企业管理'">{{ item.name }}</span>
        <span slot="title" v-else>{{ item.name}}</span>
    </el-menu-item>
</template>


<script>

export default {

    name: "treeMenu",

    watch: {
        '$route.meta.parentPath': {
            handler(newVal) {
                //判断当前路由是不是在企业管理页面，如果不在则取消菜单选中样式
                if (newVal != '/setting/enterpriseInfo') {
                    this.clearBG();
                }
            },
            deep: true
        }
    },

    props: {

        //菜单数据
        item: {
            Type: Object,
            default: () => {
            }
        }

    },

    data() {

        return {

            //路由URL
            url: this.$route.meta.parentPath,

            //当前选中菜单code
            code: ''

        }

    },

    mounted() {

        //判断当前路由是不是在企业管理页面，如果是则将code赋值为220
        if (this.$route.meta.parentPath === '/setting/enterpriseInfo') {
            this.code = "220";
        }

        //设置选中项样式
        this.selectMenu();

    },

    methods: {

        /**
         * 获取选中项详情
         * @param item
         */
        getMenuItem(item) {
            this.url = item.uri;
            this.code = '';
            this.clearBG();
            localStorage.setItem('selectMenuItem', JSON.stringify(item))
        },

        /**
         * 跳转菜单页面
         * @param item
         */
        toMenu(item) {
            if (item.uri !== "" && item.uri !== undefined && item.uri !== this.$route.meta.parentPath) {
                this.code = item.code;
                this.selectMenu();
                this.$router.push({path: item.uri})
            }
        },

        /**
         * 设置选中项样式
         */
        selectMenu() {
            this.clearBG();
            if (this.code !== "220") return;
            let menuDom = document.getElementsByClassName("menu_" + this.code)[0];
            if (menuDom) {
                menuDom.style.backgroundColor = "#1890FF";
                menuDom.style.color = "#ffffff";
                menuDom.style.borderRadius = "3px";
                menuDom.parentNode.getElementsByClassName('el-icon-arrow-down')[0].style.color = "#ffffff";
            }
        },

        /**
         * 取消选中样式
         */
        clearBG() {
            let len = document.getElementsByClassName("menu");
            for (let i = 0; i < len.length; i++) {
                len[i].style.backgroundColor = "#ffffff";
                len[i].style.color = "#000000";
                len[i].parentNode.getElementsByClassName('el-icon-arrow-down')[0].style.color = "#909399";
            }
        }

    }

}

</script>

<style scoped>
/deep/ .el-submenu__title {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
    margin-top: 3px;
}

/deep/ .el-submenu__title:hover {
    background-color: #fff;
}

.el-submenu .el-menu-item, .el-menu .el-menu-item {
    min-width: 0;
    padding-left: 56px!important;
    height: 40px;
    line-height: 40px;
    margin-top: 3px;
}


.iconfont {
    color: inherit !important;
    font-size: 20px;
    margin: 0 14px 0 11px;
    top: -1px;
    position: relative;
}

.iconfont-item {
    color: inherit !important;
    margin: 0 10px 0 -13px;
}



/deep/ .el-icon-arrow-down {
    font-size: 16px;
}

.el-menu-item:hover, .el-submenu:hover div {
    /*color: #3296FA !important;*/
    border-radius: 3px;
}

.el-menu-item.is-active {
    background-color: #1890FF !important;
    color: #ffffff !important;
    border-radius: 3px;
}

.el-menu {
    overflow: hidden;
}

/*/deep/ .el-submenu__icon-arrow{*/
/*    color: white!important;*/
/*}*/
</style>
