<template>
    <el-container class="full-container">
        <el-header class="shadow">
            <home-header></home-header>
        </el-header>

        <!-- 开放生态 start -->
        <div class="banner">
            <img src="../../assets/index/banner_bg.png" class="banner-bg">
            <div class="banner-text">
                <span>开放与共创</span>
                <p>
                    小蜂连连（或称Funlink）致力于提供端到端的智能、联网产品解决方案，具体包括云平台、智能模块、AI算法模型、移动APP。即可实现开箱即用；也提供各端的应用开发基础API，方便客户自行开发必要的个性化功能。
                </p>
            </div>
        </div>
        <!-- 开放生态 end -->

        <!-- 多种接入方式 start -->
        <div class="module">
            <el-row>
                <el-col :span="12">
                    <div class="module-text">
                        <span>云平台</span>
                        <p>
                            中台、业务平台分离设计，扩展性好<br/>
                            智能模组接入、控制、集中控制、固件空中升级、算法模型远程更新，为产品提供全方位的服务
                        </p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <img src="../../assets/index/multiple_access_methods.png" class="module-img">
                </el-col>
            </el-row>
        </div>
        <!-- 多种接入方式 end -->

        <!-- 产品解决方案 start -->
        <div class="module">
            <el-row>
                <el-col :span="12">
                    <img src="../../assets/index/product_solutions.png" class="module-img">
                </el-col>
                <el-col :span="12">
                    <div class="module-text">
                        <span>移动APP</span>
                        <p>
                            插件化开发，扩展性好<br/>
                            安卓、IOS、微信小程序多端覆盖
                        </p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 产品解决方案 end -->

        <!-- 各类智能硬件模组 start -->
        <div class="hardware-module">
            <span class="title">各类智能硬件模组</span>
            <p>智能模组原生接入云平台，免接入开发，用户只需聚焦个性化的业务开发;智能模组功能覆盖全面，按需选择</p>

            <el-row style="width: 1200px;margin: 0 auto;">
                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">强AI计算SOC模块</div>
                    <div class="table-body">
                        支持0.2~6T的NPU，1多64路摄像头输入，8K编解码；<br/>
                        支持小尺寸液晶屏显示，以及HDMI图像输出；<br/>
                        支持ubuntu、AOSP、OpenHarmony等多种OS
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">强业务计算SOC模块</div>
                    <div class="table-body">
                        支持双核~8核，主频高达1.8Hz的ARM或MIPS CPU<br/>
                        支持有线、无线联网，可驱动大尺寸液晶显示屏
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="table-header">基础连接WIFI SOC模块</div>
                    <div class="table-body" style="text-align: center;">
                        支持相关网络技术:<br/>
                        WIFI<br/>
                        WIFI4 + WIFI5<br/>
                        WIFI4 + WIFI5+蓝牙 <br/>
                        WIFI4 + WIFI 6
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 各类智能硬件模组 end -->

        <!-- AI算法模型 start -->
        <div class="ai-module">
            <span class="title">AI算法模型</span>
            <p>软硬结合、多模态数据融合，更快、更准、更省</p>

            <el-row style="width: 1200px;margin: 0 auto;">
                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai3.png" class="ai-img">
                        <span class="ai-item-title">图像</span>
                        <p>支持对象检测、识别、跟踪等</p>
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai2.png" class="ai-img">
                        <span class="ai-item-title">声音</span>
                        <p>支持回声消除、声源定位、噪声抑制等</p>
                    </div>
                </el-col>

                <el-col style="width: 300px;margin: 0 50px;">
                    <div class="ai-table-body">
                        <img src="../../assets/index/icon_ai1.png" class="ai-img">
                        <span class="ai-item-title">运动控制</span>
                        <p>快速建图、路径规划、主动避障等</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- AI算法模型 end -->

        <!-- 合作流程 start -->
        <div class="cooperation-module">
            <span class="title">合作流程</span>

            <el-row>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process1.png" class="cooperation-img">
                    <div>
                        <span>成为开发者</span>
                        <p>注册平台账号、获得开发权限</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process2.png" class="cooperation-img">
                    <div>
                        <span>创建产品</span>
                        <p>明确产品功能及技术方案</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process3.png" class="cooperation-img">
                    <div>
                        <span>研发产品</span>
                        <p>开发软硬件功能并测试通过</p>
                    </div>
                </el-col>
                <el-col :span="4">
                    <i class="el-icon-arrow-right"></i>
                </el-col>
                <el-col :span="3">
                    <img src="../../assets/index/cooperation_process4.png" class="cooperation-img">
                    <div>
                        <span>发布产品</span>
                        <p>取得平台权限即可推广销售</p>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 合作流程 end -->

        <el-footer style="height: 36px;">

            <el-link type="info" class="copyright" target="_blank" href="https://beian.miit.gov.cn">Copyright&nbsp;&nbsp;&nbsp;©2022&nbsp;&nbsp;&nbsp;广州朗国电子科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;粤ICP备14024048号</el-link>
        </el-footer>
    </el-container>
</template>

<script>

import HomeHeader from "@/views/index/components/homeHeader";

export default {

    name: "index",

    components: {HomeHeader},

    data() {

        return {

            communicationHeaderList: ["单WIFI", "NFC+WIFI", "蓝牙+WIFI", "单蓝牙"],

            AIHeaderList: ["3D人脸识别", "猫眼"],

            communicationBodyList: ["Open的SDK", "超低成本", "最快1S配网成功", "超低功耗"],

            AIBodyList: ["融合先进AI算法", "超低成本", "超低功耗", "高集成度", "高性能"]


        }

    },

    mounted() {
    },

    methods: {

    }

}

</script>

<style scoped>
.shadow {
    width: 100%;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    height: 64px !important;
    padding: 0;
    position: fixed;
    z-index: 1000;
    background-color: white;
}

.banner {
    width: 100%;
    margin-top: 64px;
    position: relative;
    text-align: center;
}

.banner-bg {
    width: 100%;
}

.banner-text {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    text-align: center;
    padding-top: 12%;
}

.banner-text span {
    font-weight: 500;
    font-size: 36px;
    color: #000000;
}

.banner-text p {
    font-weight: 500;
    width: 1000px;
    font-size: 20px;
    color: #333333;
    margin: 20px auto;
    line-height: 36px;
}

.module {
    width: 100%;
    height: 470px;
}

.module-text {
    width: 406px;
    text-align: left;
    margin: 0 auto;
    padding-top: 153px;
}

.module-text span {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}

.module-text p {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
}

.module-img {
    width: 406px;
    height: 357px;
    margin-top: 31px;
}

.hardware-module {
    width: 100%;
    height: 605px;
    background: #F5F7FB;
}

.hardware-module p, .ai-module > p {
    width: 978px;
    margin: 32px auto 48px auto;
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    text-align: center;
}

.hardware-module .title, .cooperation-module .title, .ai-module .title {
    font-weight: 500;
    font-size: 36px;
    color: #000000;
    padding-top: 80px;
    display: block;
}

.table-header {
    width: 100%;
    height: 64px;
    background-image: linear-gradient(90deg, #82B6FF 0%, #1276FE 100%);
    border-radius: 8px 8px 0 0;
    margin: 0 auto;

    line-height: 64px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}


.table-body {
    width: calc(100% - 60);
    height: 168px;
    border-radius: 0 0 8px 8px;
    background-color: white;
    margin: 0 auto;
    text-align: left;
    padding: 30px;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 28px;
}

.ai-module {
    width: 100%;
    height: 666px;
    background: #F7F7F7;
}

.ai-img {
    width: 240px;
    height: 160px;
    margin-bottom: 36px;
}

.ai-table-body {
    width: 240px;
    height: 300px;
    background: #FFFFFF;
    border: 0.6px solid rgba(0,0,0,0.26);
    border-radius: 12px;
    padding: 30px 36px;
    text-align: center;
}

.ai-item-title {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
}

.ai-table-body p{
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}

.cooperation-module {
    width: 100%;
    height: 512px;
}

.cooperation-module .el-row {
    width: 1200px;
    margin: 100px auto 0 auto;
}

.cooperation-module .el-col {
    text-align: center;
}

.cooperation-img {
    width: 88px;
    height: 88px;
}

.cooperation-module .el-col span {
    font-weight: 500;
    font-size: 16px;
    color: #333333;
}

.cooperation-module .el-col p {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
}

.cooperation-module .el-col i {
    font-size: 24px;
    margin-top: 36%;
    color: #BCBCBC;
}

.copyright {
    line-height: 36px;
}

</style>
