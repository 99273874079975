<!--<template>-->
<!--    <el-container class="full-container">-->
<!--        <el-header class="shadow">-->
<!--            <admin-header></admin-header>-->
<!--        </el-header>-->
<!--        <el-container class="main-container">-->
<!--            <el-main>-->
<!--                <user-main></user-main>-->
<!--            </el-main>-->
<!--        </el-container>-->
<!--    </el-container>-->
<!--</template>-->
<template>
    <el-container class="full-container">
        <el-header class="shadow">
            <admin-header></admin-header>
        </el-header>
        <el-container class="main-container">
            <el-aside width="232px">
                <side class="side" ref="side" :list="userMenu"></side>
            </el-aside>
            <el-main>
                <router-view/>
                <!--                <admin-main ref="adminMain"></admin-main>-->
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import adminHeader from '@/views/main/components/adminHeader';
import userMain from '@/views/setting/components/userMain';
import side from "@/views/setting/components/side";
import {mapGetters, mapState} from 'vuex';

export default {
    components: {
        adminHeader,
        userMain,
        side
    },
    computed: {
        ...mapState('login', ['menuData'])
    },
    watch: {
        menuData: {
            handler(newVal) {
                this.userMenu = newVal.settingMenuList;
                if (newVal = []) return;
                this.$router.push({path: this.getSettingMenuRedirect()})
            },
            deep: true
        },
        '$route.path': {
            handler(newVal) {
                if (newVal === '/index' || newVal === '/setting') {
                    console.log('sas', newVal)
                    this.$router.push({path: this.getSettingMenuRedirect()})
                }
            },
            deep: true
        }
    },
    data() {
        return {
            userMenu: []
        }
    },
    mounted() {
        this.userMenu = this.menuData.settingMenuList;
        if (this.$route.path === '/setting' && this.getSettingMenuRedirect() !== "") {
            this.$router.push({path: this.getSettingMenuRedirect()})
        }
    },
    methods: {
        ...mapGetters('login', ['getSettingMenuRedirect']),
    }
}
</script>

<style scoped>
/*.full-container{*/
/*  height: 100vh;*/
/*}*/
/*.shadow {*/
/*    width: 100%;*/
/*    box-shadow: 0 1px 4px 0 rgba(0,21,41,0.12);*/
/*    z-index: 20;*/
/*    padding: 0px;*/
/*    height: 48px!important;*/
/*}*/
/*.main-container{*/
/*    background-color: #E8ECF0;*/
/*    position: relative;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*}*/

.full-container {
    height: 100vh;
    overflow-y: hidden;
}

.shadow {
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
    z-index: 20;
    padding: 0;
    height: 64px !important;
}

.main-container {
    background-color: #F0F2F5;
}

.el-main {
    padding: 0px;
}

.el-aside {
    z-index: 9;
    background-color: #ffffff;
    box-shadow: 0 5px 4px 0 #E1E1E1;
    text-align: left;
    padding: 6px 5px;
}
</style>
