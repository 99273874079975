<template>
    <div>
        <el-form :inline="true" size="small" :model="form">
            <el-form-item>
                <el-input
                    prefix-icon="el-icon-search" v-model.trim="form.keyword" clearable placeholder="设备ID"
                    @clear="getMsg(1)"
                    @keyup.enter.native="getMsg(1)" @blur="getMsg(1)"></el-input>
            </el-form-item>

            <el-form-item>
                <el-date-picker
                    v-model="form.time"
                    type="datetimerange"
                    clearable
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="timeChange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-select v-model="form.logType" :popper-append-to-body="false" clearable placeholder="请选择">
                    <el-option
                        v-for="item in $message.logTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <el-table
            ref="logTable"
            class="log_table"
            :data="logMsg.list"
            :height="tableHeight"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            v-loading="loading"
            style="width: 100%;">

            <el-table-column
                label="时间">
            </el-table-column>

            <el-table-column
                label="类型">
            </el-table-column>

            <el-table-column
                label="内容">
            </el-table-column>

        </el-table>

        <el-pagination
            background
            :current-page="page"
            @current-change="handleCurrentChange"
            :page-size="10"
            layout=" prev, pager, next"
            style="margin-top: 10px;text-align: right;"
            :total="parseInt(logMsg.totalRecords)">
        </el-pagination>

    </div>
</template>

<script>
export default {

    name: "communicationLog",

    data() {

        return {

            tableHeight: 300,

            loading: false,

            logMsg: {
                list: [],
                totalRecords: 10
            },

            page: 1,

            form: {
                //设备ID
                keyword: "",
                //时间区间
                time: "",
                //开始时间
                startTime: "",
                //结束时间
                endTime: "",
                //日志类型
                logType: ""
            }

        }

    },

    mounted() {
    },

    methods: {

        getMsg(page) {

        },

        timeChange(val) {
            this.form.startTime = val ? val[0] : '';
            this.form.endTime = val ? val[1] : '';
            this.getMsg(1);
        },

        setHeight() {
            this.tableHeight = window.innerHeight - 310;
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg(1);
        }

    }

}
</script>

<style scoped>
.el-select {
    width: 100px;
}

/deep/ .el-form--inline .el-form-item {
    margin-right: 16px!important;
}
</style>
