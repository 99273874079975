<template>
    <div>

        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            授权服务详情
        </div>
        <!-- 标题 end -->

        <div class="body">

            <!-- 授权详情 -->
            <authorization-msg class="auth_msg"></authorization-msg>

            <!-- 激活列表 -->
            <device-sign-table></device-sign-table>

<!--            &lt;!&ndash; License列表 &ndash;&gt;-->
<!--            <license-table></license-table>-->

        </div>

    </div>
</template>

<script>

import AuthorizationMsg from "@/views/empower/components/authorizationMsg";
import DeviceSignTable from "@/views/empower/components/deviceSignTable";
import LicenseTable from "@/views/empower/components/licenseTable";

export default {

    name: "empowerMsg",

    components: {LicenseTable, DeviceSignTable, AuthorizationMsg},

    data() {
        return {}
    },

    methods: {

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        }

    }

}
</script>

<style scoped>
.el-card {
    background: #FFFFFF;
    border-radius: 8px;
    border: none;
    margin: 64px 24px 24px 24px;
}

/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.body {
    margin: 64px 24px 24px 24px;
}
</style>
